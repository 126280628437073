import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Comp
import Item from "components/UserProfile/Items/Item";

const SubHeader = () => {
  const { homePageBannerSetting } = useSelector(
    (state) => state.Login?.settings || {}
  );

  return (
    <section
      className="bannersec d-table w-100 position-relative "
      style={{
        backgroundImage: "url('images/bg/bg01.pngds')",
        backgroundPosition: "center center",
        backgroundSize: "contain",
      }}
    >
      <div className="bg-overlay opacity-4"></div>
      <div className="container">
        <div className="row align-items-center border-style">
          <div className="col-lg-7 col-md-6">
            <div className="title-heading">
              <h6 className="text-black title-dark fw-normal PH-font">
                {homePageBannerSetting?.topHeading}
              </h6>
              <h4 className="heading text-black title-dark fw-bold mb-3 PH-font">
                {homePageBannerSetting?.subHeading}
              </h4>
              <p className="text-black para-desc mb-0 mb-0 SH-font">
                {homePageBannerSetting?.description}
              </p>

              <div className="mt-2 pt-2 btn-wrap">
                <Link
                  to="/about"
                  className="btn btn-pills btn-outline-light-black btn-link primary explore-now"
                >
                  Explore Now
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
            {homePageBannerSetting?.item && (
              <Item needDetails={false} item={homePageBannerSetting.item} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubHeader;

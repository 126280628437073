import React from "react"

// Components
import Footer from "../components/footer/Footer"
import Header from "../components/header/Header"
import WalletMidSection from "../components/wallet/WalletMidSection"

const Wallet = () => {
  return (
    <React.Fragment>
      <Header />
      <WalletMidSection />
      <Footer />
    </React.Fragment>
  )
}

export default Wallet

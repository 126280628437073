import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Spinner } from "reactstrap"
import { connect } from "react-redux"

// Actions
import { getCollections } from "store/actions"

//
import Collection from "components/UserProfile/Collections/Collection"

const CONTENT = {
  preHeading: "Collections",
  heading: "My Collections",
  btnText: "Create Collection",
}

const Collections = (props) => {
  const [state, setState] = useState({
    data: CONTENT,
    collectionData: [],
  })

  const [filter, setfilter] = useState({
    limit: 10,
    orderBy: "",
    order: "",
    page: 1,
    author: props.user?._id,
  })

  useEffect(() => {
    if (props.user?._id) {
      setfilter((prevState) => ({
        ...prevState,
        author: props.user?._id,
        page: 1,
      }))
    }
  }, [props.user?._id])

  useEffect(() => {
    props.onGetCollections(filter)
  }, [filter])

  useEffect(() => {
    if (props.collections) {
      if (filter.isMore) {
        setState((prevState) => ({
          ...prevState,
          collectionData: [...prevState.collectionData, ...props.collections],
        }))
      } else {
        setState((prevState) => ({
          ...prevState,
          collectionData: props.collections,
        }))
      }
    }
  }, [props.collections])

  const showMoreItems = () => {
    setfilter((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
      isMore: true,
    }))
  }

  return (
    <div className="">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* Intro */}
            <div className="intro d-flex justify-content-between align-items-center m-0">
              <div className="intro-content">
                <h1 style={{ color: "#da512b" }}>{state.data.preHeading}</h1>
              </div>
              <div className="intro-btn">
                <Link
                  className="btn content-btn text-left m-auto"
                  to="/create-collection"
                >
                  {state.data.btnText}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row items">
          {state.collectionData.map((item, idx) => {
            return (
              <div key={`cd_${idx}`} className="col-12">
                <Collection item={item} />
              </div>
            )
          })}
        </div>

        {state.collectionData.length < props.totalItems && (
          <div className="row">
            <div className="col-12 text-center">
              <a
                className="btn btn-bordered-white mt-5"
                href="#"
                onClick={showMoreItems}
              >
                Load More
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = ({ Login, collections }) => ({
  user: Login.user,
  collections: collections.collections,
  loading: collections.loading,
  totalCollections: collections.totalCollections,
})

const mapDispatchToProps = (dispatch) => ({
  onGetCollections: (data, callback) =>
    dispatch(getCollections(data, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Collections)

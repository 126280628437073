import {
  GET_CATEGORIES,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
} from "./actionTypes"

/* Get categories */
export const getCategories = (data) => ({
  type: GET_CATEGORIES,
  payload: data,
})

export const getCategoriesSuccess = (data) => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: data,
})

export const getCategoriesFail = (error) => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
})
/* Get Categories END */

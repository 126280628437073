import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Spinner } from "reactstrap"
import classNames from "classnames"

// Actions
import { getActivities } from "store/actions"

// Components
import ActivityItem from "components/UserProfile/Activities/Activity"

const Activities = (props) => {
  const [currentTab, setcurrentTab] = useState("")
  const [filter, setfilter] = useState({
    limit: 10,
    orderBy: "",
    order: "",
    page: 1,
    fieldName: "history_type",
    fieldValue: "",
  })
  const [activities, setactivities] = useState([])

  useEffect(() => {
    props.onGetActivities(filter)
  }, [filter])

  const handleTabChange = (value) => (evt) => {
    evt.preventDefault()

    setcurrentTab(value)
    setfilter((prevState) => ({ ...prevState, fieldValue: value }))
  }

  useEffect(() => {
    if (props.activities) {
      if (filter.isMore) {
        setactivities((prevState) => [...prevState, ...props.activities])
      } else {
        setactivities(props.activities)
      }
    }
  }, [props.activities])

  const showMoreItems = () => {
    setfilter((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
      isMore: true,
    }))
  }

  return (
    <div className="container spinner-content">
      {props.loading && (
        <div className="spinner">
          <Spinner color="primary" />
        </div>
      )}

      <div className="row justify-content-center mt-4 mb-5">
        <div className="col filters-group-wrap">
          <div className="filters-group">
            <ul className="container-filter mb-0 categories-filter text-center list-unstyled filter-options">
              <li
                className={classNames(
                  "list-inline-item  position-relative text-dark SH",
                  { active: !currentTab },
                )}
                onClick={handleTabChange("")}
                data-group="all"
              >
                All
              </li>

              <li
                className={classNames(
                  "list-inline-item  position-relative text-dark SH",
                  { active: currentTab === "minted" },
                )}
                onClick={handleTabChange("minted")}
                data-group="all"
              >
                Minted
              </li>

              <li
                className={classNames(
                  "list-inline-item  position-relative text-dark SH",
                  { active: currentTab === "transfer" },
                )}
                onClick={handleTabChange("transfer")}
                data-group="all"
              >
                Purchase
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="">
        {activities.map((item, idx) => (
          <div className="mb-3">
            <ActivityItem key={`ato_${idx}`} item={item} />
          </div>
        ))}
      </div>

      {activities.length < props.totalactivities && (
        <div className="row">
          <div className="col">
            <div className="text-center">
              <a
                href="#"
                className="btn btn-link primary text-dark"
                onClick={showMoreItems}
              >
                See More <i className="uil uil-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ items }) => ({
  activities: items.activities,
  totalactivities: items.totalactivities,
})

const mapDispatchToProps = (dispatch) => ({
  onGetActivities: (data, callback) => dispatch(getActivities(data, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Activities)

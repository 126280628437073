import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAIL,
  GET_SETTINGS,
  GET_SETTINGS_FAIL,
  GET_SETTINGS_SUCCESS,
} from "./actionTypes"

const { data, ...authUser } = JSON.parse(
  localStorage.getItem("authUser") || "{}",
)

const initialState = {
  meLoading: false,
  isMeFailed: false,
  error: "",
  loading: false,
  user: data || {},
  authToken: data ? data.Token || null : null,
  settings: null,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        error: "",
        loading: true,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        error: "",
        user: action.payload.data,
        authToken: action.payload.data.Token,
        loading: false,
      }

    case LOGOUT_USER:
      return { ...state }
    case LOGOUT_USER_SUCCESS:
      return { ...state, user: {}, authToken: null }

    case API_ERROR:
      return { ...state, error: action.payload, loading: false }

    case GET_CURRENT_USER:
      return {
        ...state,
        error: "",
        meLoading: true,
        isMeFailed: false,
      }

    case GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        error: "",
        user: action.payload.data,
        meLoading: false,
        isMeFailed: false,
      }

    case GET_CURRENT_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isMeFailed: true,
        meLoading: false,
      }

    case GET_SETTINGS:
      return {
        ...state,
        loading: true,
      }

    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: action.payload,
      }

    case GET_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
      }

    default:
      return { ...state }
  }
  return state
}

export default login

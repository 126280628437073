import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// Actions
import { getCollections } from "store/actions";

//
import Collection from "./Collection";

const Collections = (props) => {
  const [collectionData, setcollectionData] = useState([]);

  const [filter, setfilter] = useState({
    limit: 10,
    orderBy: "",
    order: "",
    page: 1,
    author: props.userId,
  });

  useEffect(() => {
    if (props.userId) {
      setfilter((prevState) => ({
        ...prevState,
        author: props.userId,
        page: 1,
      }));
    }
  }, [props.userId]);

  useEffect(() => {
    if (!filter.author) return;
    props.onGetCollections(filter);
  }, [filter]);

  useEffect(() => {
    if (props.collections) {
      if (filter.isMore) {
        setcollectionData((prevState) => [...prevState, ...props.collections]);
      } else {
        setcollectionData(props.collections);
      }
    }
  }, [props.collections]);

  const showMoreItems = () => {
    setfilter((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
      isMore: true,
    }));
  };

  return (
    <div className="row items">
      {collectionData.map((item, idx) => (
        <div key={`eds_${idx}`} className="col-12 mt-3">
          <Collection item={item} />
        </div>
      ))}

      {collectionData.length < props.totalCollections && (
        <div className="row justify-content-center mt-4">
          <div className="col">
            <div className="text-center">
              <a
                href="#"
                onClick={showMoreItems}
                className="btn btn-primary rounded-md"
              >
                <i className="uil uil-process mdi-spin me-1"></i> Load More
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ Login, collections }) => ({
  user: Login.user,
  collections: collections.collections,
  loading: collections.loading,
  totalCollections: collections.totalCollections,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCollections: (data, callback) =>
    dispatch(getCollections(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Collections);

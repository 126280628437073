import { all, fork } from "redux-saga/effects";

import Alert from "./Alert/saga";

//public
import AuthSaga from "./auth/login/saga";
import Register from "./auth/register/saga";
import Profile from "./auth/profile/saga";

// File
import File from "./File/saga";

// Home
import home from "./home/saga";

// Collections
import collections from "./collection/saga";

// Categories
import categories from "./category/saga";

// Items
import items from "./items/saga";

import notifications from "./notification/saga";

// Content Pages
import contentpage from "./contentpage/saga";

// Faq
import Faq from "./faqs/saga";

export default function* rootSaga() {
  yield all([
    fork(Alert),
    fork(AuthSaga),
    Register(),
    fork(Profile),
    fork(File),
    fork(home),
    fork(collections),
    fork(categories),
    fork(items),
    fork(notifications),
    fork(contentpage),
    fork(Faq),
  ]);
}

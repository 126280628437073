import {
  GET_HOMEPAGE,
  GET_HOMEPAGE_SUCCESS,
  GET_HOMEPAGE_FAIL,
  GET_MOSTLY_VIEW,
  GET_MOSTLY_VIEW_SUCCESS,
  GET_MOSTLY_VIEW_FAIL,
  GET_LATEST,
  GET_LATEST_SUCCESS,
  GET_LATEST_FAIL,
  GET_TOP_COLLECTIONS,
  GET_TOP_COLLECTIONS_SUCCESS,
  GET_TOP_COLLECTIONS_FAIL,
  GET_EXPLORE,
  GET_EXPLORE_SUCCESS,
  GET_EXPLORE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  loading: false,
  mostViewed: [],
  latestNft: [],
  topCollections: [],
  topUsers: [],
  explore: [],
}

const Home = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Homepage */
    case GET_HOMEPAGE:
      return {
        ...state,
        loading: true,
      }

    case GET_HOMEPAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        mostViewed: action.payload.mostViewed,
        latestNft: action.payload.latestNft,
        topCollections: action.payload.topCollections,
        topUsers: action.payload.users,
        explore: action.payload.explore,
      }

    case GET_HOMEPAGE_FAIL:
      return {
        ...state,
        loading: false,
      }
    /* Get Homepage END */

    /* Get Mostly Viewed */
    case GET_MOSTLY_VIEW:
      return {
        ...state,
        loading: true,
      }

    case GET_MOSTLY_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        mostViewed: action.payload.data,
        totalMostViewed: action.payload.totalCount,
      }

    case GET_MOSTLY_VIEW_FAIL:
      return {
        ...state,
        loading: false,
      }
    /* Get Mostly Viewed END */

    /* Get Latest */
    case GET_LATEST:
      return {
        ...state,
        loading: true,
      }

    case GET_LATEST_SUCCESS:
      return {
        ...state,
        loading: false,
        latestNft: action.payload.data,
        totalLatestNft: action.payload.totalCount,
      }

    case GET_LATEST_FAIL:
      return {
        ...state,
        loading: false,
      }
    /* Get Latest END */

    /* Get Top Collections */
    case GET_TOP_COLLECTIONS:
      return {
        ...state,
        loading: true,
      }

    case GET_TOP_COLLECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        topCollections: action.payload.data,
        totalTopCollections: action.payload.totalCount,
      }

    case GET_TOP_COLLECTIONS_FAIL:
      return {
        ...state,
        loading: false,
      }
    /* Get Top Collections END */

    /* Get Explore */
    case GET_EXPLORE:
      return {
        ...state,
        loading: true,
      }

    case GET_EXPLORE_SUCCESS:
      return {
        ...state,
        loading: false,
        explore: action.payload.data,
        totalExplore: action.payload.totalCount,
      }

    case GET_EXPLORE_FAIL:
      return {
        ...state,
        loading: false,
      }
    /* Get Explore END */

    default:
      return state
  }
}

export default Home

import {
  GET_COLLECTION_NAME_VERIFY,
  GET_COLLECTION_NAME_VERIFY_FAIL,
  GET_COLLECTION_NAME_VERIFY_SUCCESS,
  GET_COLLECTION_BYTECODE,
  GET_COLLECTION_BYTECODE_FAIL,
  GET_COLLECTION_BYTECODE_SUCCESS,
  GET_COLLECTIONS,
  GET_COLLECTIONS_FAIL,
  GET_COLLECTIONS_SUCCESS,
  POST_COLLECTION,
  POST_COLLECTION_FAIL,
  POST_COLLECTION_SUCCESS,
  GET_COLLECTION,
  GET_COLLECTION_BY_ADDRESS,
  GET_COLLECTION_FAIL,
  GET_COLLECTION_SUCCESS,
  PUT_COLLECTION,
  PUT_COLLECTION_FAIL,
  PUT_COLLECTION_SUCCESS,
  DELETE_COLLECTION,
  DELETE_COLLECTION_FAIL,
  DELETE_COLLECTION_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  error: "",
  loading: false,
  collections: [],
  totalCollections: 0,
  collection: {},
  collectionItems: 0,
}

const Collections = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Collection Name Verify */
    case GET_COLLECTION_NAME_VERIFY:
    case GET_COLLECTION_BYTECODE:
      return {
        ...state,
        loading: true,
      }

    case GET_COLLECTION_NAME_VERIFY_SUCCESS:
    case GET_COLLECTION_BYTECODE_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case GET_COLLECTION_NAME_VERIFY_FAIL:
    case GET_COLLECTION_BYTECODE_FAIL:
      return {
        ...state,
        loading: false,
      }
    /* Get Collection Name Verify */

    /* Get Collections */
    case GET_COLLECTIONS:
      return {
        ...state,
        loading: true,
      }

    case GET_COLLECTIONS_SUCCESS:
      return {
        ...state,
        collections: action.payload.data || [],
        totalCollections: action.payload.totalCount || 0,
        loading: false,
        error: "",
      }

    case GET_COLLECTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Get Collections END */

    /* Add Collection */
    case POST_COLLECTION:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case POST_COLLECTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case POST_COLLECTION_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Add Collection END */

    /* Get Collection */
    case GET_COLLECTION:
    case GET_COLLECTION_BY_ADDRESS:
      return {
        ...state,
        error: "",
        loading: true,
        collection: {},
      }

    case GET_COLLECTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        collection: {},
      }

    case GET_COLLECTION_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        collection: action.payload.data,
        collectionItems: action.payload.collectionItems,
      }
    /* Add Collection END */

    /* Update Collection */
    case PUT_COLLECTION:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case PUT_COLLECTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case PUT_COLLECTION_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Update Collection END */

    /* Delete Collection */
    case DELETE_COLLECTION:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case DELETE_COLLECTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_COLLECTION_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Delete Collection END */

    default:
      return state
  }
}

export default Collections

import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Spinner } from "reactstrap"

//
import Item from "./Item"

// Actions
import { getItems } from "store/actions"

const Items = (props) => {
  const [itemData, setitemData] = useState([])

  const [filter, setfilter] = useState({
    limit: 10,
    orderBy: "",
    order: "",
    page: 1,
  })

  useEffect(() => {
    if (props.userId) {
      if (props.currentTab === "created") {
        setfilter((prevState) => ({
          ...prevState,
          author: null,
          collectedAuthor: null,
          createdAuthor: props.userId,
          page: 1,
          isMore: false,
        }))
      } else if (props.currentTab === "collected") {
        setfilter((prevState) => ({
          ...prevState,
          author: null,
          createdAuthor: null,
          collectedAuthor: props.userId,
          page: 1,
          isMore: false,
        }))
      } else {
        setfilter((prevState) => ({
          ...prevState,
          collectedAuthor: null,
          createdAuthor: null,
          author: props.userId,
          page: 1,
          isMore: false,
        }))
      }
    }
  }, [props.userId, props.currentTab])

  useEffect(() => {
    if (!filter.author && !filter.collectedAuthor && !filter.createdAuthor)
      return
    props.onGetItems(filter)
  }, [filter])

  useEffect(() => {
    if (props.items) {
      if (filter.isMore) {
        setitemData((prevState) => [...prevState, ...props.items])
      } else {
        setitemData(props.items)
      }
    }
  }, [props.items])

  const showMoreItems = () => {
    setfilter((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
      isMore: true,
    }))
  }

  return (
    <div className="row items spinner-content">
      {props.loading && (
        <div className="spinner">
          <Spinner color="primary" />
        </div>
      )}

      {/* Single Slide */}
      {itemData.map((item, idx) => (
        <div key={`exo_${idx}`} className="col-12 col-md-4 item ">
          <Item item={item} />
        </div>
      ))}

      {itemData.length < props.totalItems && (
        <div className="row justify-content-center mt-4">
          <div className="col">
            <div className="text-center">
              <a
                href="#"
                onClick={showMoreItems}
                className="btn btn-primary rounded-md"
              >
                <i className="uil uil-process mdi-spin me-1"></i> Load More
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ collections, items }) => ({
  collectionLoading: collections.loading,
  items: items.items,
  loading: items.loading,
  totalItems: items.totalItems,
})

const mapDispatchToProps = (dispatch) => ({
  onGetItems: (data, callback) => dispatch(getItems(data, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Items)

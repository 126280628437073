import React from "react"

import Footer from "../components/footer/Footer"
import Header from "../components/header/Header"

//
import MyCollections from "components/MyCollections/Collections"

const Collections = () => {
  return (
    <React.Fragment>
      <Header />
      <MyCollections />
      <Footer />
    </React.Fragment>
  )
}

export default Collections

import {
  GET_COLLECTION_NAME_VERIFY,
  GET_COLLECTION_NAME_VERIFY_FAIL,
  GET_COLLECTION_NAME_VERIFY_SUCCESS,
  GET_COLLECTION_BYTECODE,
  GET_COLLECTION_BYTECODE_FAIL,
  GET_COLLECTION_BYTECODE_SUCCESS,
  GET_COLLECTIONS,
  GET_COLLECTIONS_FAIL,
  GET_COLLECTIONS_SUCCESS,
  POST_COLLECTION,
  POST_COLLECTION_FAIL,
  POST_COLLECTION_SUCCESS,
  GET_COLLECTION,
  GET_COLLECTION_BY_ADDRESS,
  GET_COLLECTION_FAIL,
  GET_COLLECTION_SUCCESS,
  PUT_COLLECTION,
  PUT_COLLECTION_FAIL,
  PUT_COLLECTION_SUCCESS,
  DELETE_COLLECTION,
  DELETE_COLLECTION_FAIL,
  DELETE_COLLECTION_SUCCESS,
} from "./actionTypes"

/* Verify Collection By Name */
export const getCollectionNameVerify = (name, callback) => ({
  type: GET_COLLECTION_NAME_VERIFY,
  payload: { name, callback },
})

export const getCollectionNameVerifySuccess = () => ({
  type: GET_COLLECTION_NAME_VERIFY_SUCCESS,
})

export const getCollectionNameVerifyFail = () => ({
  type: GET_COLLECTION_NAME_VERIFY_FAIL,
})
/* Verify Collection By Name */

/* Get Collection Bytecode */
export const getCollectionBytecode = (data, callback) => ({
  type: GET_COLLECTION_BYTECODE,
  payload: { data, callback },
})

export const getCollectionBytecodeSuccess = () => ({
  type: GET_COLLECTION_BYTECODE_SUCCESS,
})

export const getCollectionBytecodeFail = () => ({
  type: GET_COLLECTION_BYTECODE_FAIL,
})
/* Get Collection Bytecode */

/* Get collections */
export const getCollections = (data) => ({
  type: GET_COLLECTIONS,
  payload: data,
})

export const getCollectionsSuccess = (data) => ({
  type: GET_COLLECTIONS_SUCCESS,
  payload: data,
})

export const getCollectionsFail = (error) => ({
  type: GET_COLLECTIONS_FAIL,
  payload: error,
})
/* Get Collections END */

/* Add Collection */
export const postCollection = (collection, callback) => ({
  type: POST_COLLECTION,
  payload: { collection, callback },
})

export const postCollectionFail = (error) => ({
  type: POST_COLLECTION_FAIL,
  payload: error,
})

export const postCollectionSuccess = () => ({
  type: POST_COLLECTION_SUCCESS,
})
/* Add Collection END */

/* Get Collection */
export const getCollection = (id, callback) => ({
  type: GET_COLLECTION,
  payload: { id, callback },
})

export const getCollectionByAddress = (address, callback) => ({
  type: GET_COLLECTION_BY_ADDRESS,
  payload: { address, callback },
})

export const getCollectionFail = (error) => ({
  type: GET_COLLECTION_FAIL,
  payload: error,
})

export const getCollectionSuccess = (data) => ({
  type: GET_COLLECTION_SUCCESS,
  payload: data,
})
/* Get Collection END */

/* Update Collection */
export const putCollection = (data, callback) => ({
  type: PUT_COLLECTION,
  payload: { data, callback },
})

export const putCollectionFail = (error) => ({
  type: PUT_COLLECTION_FAIL,
  payload: error,
})

export const putCollectionSuccess = () => ({
  type: PUT_COLLECTION_SUCCESS,
})
/* Update Collection END */

/* Delete Collection */
export const deleteCollection = (data, callback) => ({
  type: DELETE_COLLECTION,
  payload: { data, callback },
})

export const deleteCollectionFail = (error) => ({
  type: DELETE_COLLECTION_FAIL,
  payload: error,
})

export const deleteCollectionSuccess = () => ({
  type: DELETE_COLLECTION_SUCCESS,
})
/* Delete Collection END */

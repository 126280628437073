import React from "react";
import { Provider } from "react-redux";
import "toastr/build/toastr.min.css";
import "./App.css";

// Hooks
import { WalletProvider } from "./util/wallet";

// Store
import store from "./store";

// importing MyRouts where we located all of our theme
import MyRouts from "./routers/routes";

import Soon from "./pages/Soon";

/* if (process.env.NODE_ENV !== "development") {
  console.log = () => { }
  console.error = () => { }
  console.warn = () => { }
  console.info = () => { }
}
if (process.env.NODE_ENV === "development") {
  console.error = () => { }
  console.warn = () => { }
  console.info = () => { }
} */

function App() {
  return (
    <WalletProvider>
      <Provider store={store}>
        <Soon />
      </Provider>
    </WalletProvider>
  );
}

export default App;

import React from "react"
import Footer from "components/footer/Footer"
import Header from "components/header/Header"
import Itemdescription from "components/itemdetails/Itemdescription"
import RelatedAuctionDetail from "components/itemdetails/RelatedAuctionDetail"

const ItemDetail = () => {
  return (
    <React.Fragment>
      <Header />
      <Itemdescription />
      <RelatedAuctionDetail />
      <Footer />
    </React.Fragment>
  )
}

export default ItemDetail

export const EDIT_PROFILE = "EDIT_PROFILE"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"

export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL"

export const PUT_PROFILE = "PUT_PROFILE"
export const PUT_PROFILE_SUCCESS = "PUT_PROFILE_SUCCESS"
export const PUT_PROFILE_FAIL = "PUT_PROFILE_FAIL"

export const PUT_PROFILE_IMAGE = "PUT_PROFILE_IMAGE"
export const PUT_PROFILE_IMAGE_SUCCESS = "PUT_PROFILE_IMAGE_SUCCESS"
export const PUT_PROFILE_IMAGE_FAIL = "PUT_PROFILE_IMAGE_FAIL"

export const GET_USER_BY_WALLET = "GET_USER_BY_WALLET"
export const GET_USER_BY_WALLET_SUCCESS = "GET_USER_BY_WALLET_SUCCESS"
export const GET_USER_BY_WALLET_FAIL = "GET_USER_BY_WALLET_FAIL"

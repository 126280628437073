import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_SUCCESS,
} from "./actionTypes"

/* Get Notifications */
export const getNotifications = (data) => ({
  type: GET_NOTIFICATIONS,
  payload: data,
})

export const getNotificationsSuccess = (data) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: data,
})

export const getNotificationsFail = (error) => ({
  type: GET_NOTIFICATIONS_FAIL,
  payload: error,
})
/* Get Notifications END */

import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";

const Pages404 = () => {
  return (
    <div className="main">
      <header id="topnav" className="defaultscroll sticky shadow-lg">
        <div className="container">
          <div className="clearfix">
            <Link to="/" className="logo">
              <span className="logo-light-mode">
                <img
                  src="/images/artspray-logo.png"
                  height="26"
                  className="l-dark"
                  alt=""
                />
                <img
                  src="/images/artspray-logo.png"
                  height="26"
                  className="l-light"
                  alt=""
                />
              </span>
              <img
                src="/images/artspray-logo.png"
                height="26"
                className="logo-dark-mode"
                alt=""
              />
            </Link>
          </div>

          <div className="menu-extras">
            <div className="menu-item"></div>
          </div>

          <ul className="buy-button list-inline mb-0">
            <li className="list-inline-item mb-0 me-1 px-2">
              {/* <button type="button" onClick={searchHandler} className={"btn dropdown-toggle p-0" + (openDropdown.search ? " show" : "")} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded={openDropdown.search}>
                            <i className="uil uil-search text-black title-dark btn-icon-light fs-5 align-middle"></i>
                            <i className="uil uil-search text-dark btn-icon-dark fs-5 align-middle"></i>
                        </button> */}
              {/* <div
                className={
                  "bg-white res-search shadow rounded border-0 mt-0 p-0 show"
                }
                style={{
                }}
              >
                {/* <div className="search-bar">
                  <div id="itemSearch" className="menu-search mb-0">
                    <form
                      role="search"
                      method="get"
                      id="searchItemform"
                      className="searchform"
                    >
                      <input
                        type="text"
                        className="form-control border rounded shadow"
                        name="s"
                        id="s"
                        placeholder="Search..."
                      />
                      <input
                        type="submit"
                        id="searchItemsubmit"
                        value="Search"
                      />
                    </form>
                  </div>
                </div> */}
              {/* </div> */}
            </li>
          </ul>
        </div>
      </header>

      <div className="account-pages my-5 pt-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1 className="display-2 font-weight-medium">Coming soon</h1>
                <h4 className="text-uppercase">We are building our website.</h4>
                {/* <div className="mt-5 text-center">
                  <Link className="btn-404" to="/">
                    Back to Home
                  </Link>
                </div> */}
              </div>
            </Col>
          </Row>
          {/*  <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src="/img/error-img.png" alt="" className="img-fluid" />
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </div>
  );
};

export default Pages404;

import toastr from "toastr";

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = { address: addressArray[0] };

      return obj;
    } catch (err) {
      return { address: "" };
    }
  } else {
    window.open("https://metamask.io/download.html", "_blank");
    toastr.error(
      "You must install Metamask, a virtual Ethereum wallet, in your browser."
    );

    return { address: "" };
  }
};

export const getCurrentWalletConnected = async () => {
  try {
    const addressArray = await window.ethereum.request({
      method: "eth_accounts",
    });

    if (addressArray.length > 0) {
      return { address: addressArray[0] };
    }

    return { address: "" };
  } catch (err) {
    return { address: "" };
  }
};

export const onAccountChange = (callback) => {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", callback);
  }
};

export const getCurrentChain = () => {
  try {
    return window.ethereum.request({
      method: "eth_chainId",
      params: [],
    });
  } catch (err) {}
};

export const onChainChange = (callback) => {
  if (window.ethereum) {
    window.ethereum.on("chainChanged", callback);
  }
};

export const switchChain = (params) => {
  if (window.ethereum) {
    return window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [params],
    });
  }
};

export const addChain = (params) => {
  if (window.ethereum) {
    return window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [params],
    });
  }
};

export const sendTransaction = (params) => {
  try {
    return window.ethereum.request({
      method: "eth_sendTransaction",
      params: [params],
    });
  } catch (err) {}
};

export const disconnect = () => {};

import {
  GET_ITEMS,
  GET_ITEMS_FAIL,
  GET_ITEMS_SUCCESS,
  POST_ITEM,
  POST_ITEM_FAIL,
  POST_ITEM_SUCCESS,
  POST_ITEM_SALE,
  POST_ITEM_SALE_FAIL,
  POST_ITEM_SALE_SUCCESS,
  GET_ITEM,
  GET_ITEM_BY_TOKEN,
  GET_ITEM_FAIL,
  GET_ITEM_SUCCESS,
  PUT_ITEM,
  PUT_ITEM_FAIL,
  PUT_ITEM_SUCCESS,
  DELETE_ITEM,
  DELETE_ITEM_FAIL,
  DELETE_ITEM_SUCCESS,
  ITEM_API_FAIL,
  GET_ACTIVITIES,
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITIES_FAIL,
  POST_ITEM_BUY,
  POST_ITEM_BUY_SUCCESS,
  POST_ITEM_BUY_FAIL,
  POST_TOGGLE_ITEM_SALE,
  POST_TOGGLE_ITEM_SALE_SUCCESS,
  POST_TOGGLE_ITEM_SALE_FAIL,
  POST_BID,
  POST_BID_SUCCESS,
  POST_BID_FAIL,
  DELETE_BID,
  DELETE_BID_SUCCESS,
  DELETE_BID_FAIL,
  POST_BID_ACTION,
  POST_BID_ACTION_SUCCESS,
  POST_BID_ACTION_FAIL,
  GET_BIDS,
  GET_BIDS_SUCCESS,
  GET_BIDS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  error: "",
  loading: false,
  items: [],
  totalItems: 0,
  item: {},
  itemDetails: null,
  activities: [],
  bids: [],
  totalBids: 0,
  totalEarning: [],
}

const Items = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Items */
    case GET_ITEMS:
      return {
        ...state,
        loading: true,
      }

    case GET_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.payload.data || [],
        totalItems: action.payload.totalcount || 0,
        loading: false,
        error: "",
      }

    case GET_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Get Items END */

    /* Add Item */
    case POST_ITEM:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case POST_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case POST_ITEM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Add Item END */

    /* Post Item on Sale */
    case POST_ITEM_SALE:
      return {
        ...state,
        loading: true,
      }

    case POST_ITEM_SALE_FAIL:
      return {
        ...state,
        loading: false,
      }

    case POST_ITEM_SALE_SUCCESS:
      const _itemIndex = state.items.findIndex(
        (item) => item._id === action.payload._id,
      )

      if (_itemIndex === -1) {
        return {
          ...state,
          loading: false,
        }
      } else {
        return {
          ...state,
          loading: false,
          items: [
            ...state.items.slice(0, _itemIndex),
            action.payload,
            ...state.items.slice(_itemIndex + 1),
          ],
        }
      }
    /* Post Item on Sale END */

    /* Get Item */
    case GET_ITEM:
    case GET_ITEM_BY_TOKEN:
      return {
        ...state,
        error: "",
        loading: true,
        item: {},
      }

    case GET_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        item: {},
      }

    case GET_ITEM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        item: action.payload.data,
        itemDetails: action.payload,
      }
    /* Add Item END */

    /* Update Item */
    case PUT_ITEM:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case PUT_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case PUT_ITEM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Update Item END */

    /* Delete Item */
    case DELETE_ITEM:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case DELETE_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_ITEM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Delete Item END */

    /* Get Activities */
    case GET_ACTIVITIES:
      return {
        ...state,
        loading: true,
        error: "",
      }

    case GET_ACTIVITIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        activities: action.payload.data,
        totalactivities: action.payload.totalCount,
        totalEarning: action.payload.totalEarning,
        error: "",
      }
    /* Get Activities END */

    /* Post Item Buy */
    case POST_ITEM_BUY:
      return {
        ...state,
        loading: true,
        error: "",
      }

    case POST_ITEM_BUY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case POST_ITEM_BUY_SUCCESS:
      return {
        ...state,
        loading: false,
        itemDetails: action.payload,
        error: "",
      }
    /* Post Item Buy END */

    /* Toggle Item Sale */
    case POST_TOGGLE_ITEM_SALE:
      return {
        ...state,
        loading: true,
      }

    case POST_TOGGLE_ITEM_SALE_SUCCESS:
      const _toggleitemIndex = state.items.findIndex(
        (item) => item._id === action.payload._id,
      )

      if (_toggleitemIndex === -1) {
        return {
          ...state,
          loading: false,
        }
      } else {
        return {
          ...state,
          loading: false,
          items: [
            ...state.items.slice(0, _toggleitemIndex),
            action.payload,
            ...state.items.slice(_toggleitemIndex + 1),
          ],
        }
      }

    case POST_TOGGLE_ITEM_SALE_FAIL:
      return {
        ...state,
        loading: false,
      }
    /* Toggle Item Sale END */

    /* Post Bid */
    case POST_BID:
      return {
        ...state,
        loading: true,
      }

    case POST_BID_SUCCESS:
    case POST_BID_FAIL:
      return {
        ...state,
        loading: false,
      }
    /* Post Bid END */

    /* Delete Bid */
    case DELETE_BID:
      return {
        ...state,
        loading: true,
      }

    case DELETE_BID_SUCCESS:
    case DELETE_BID_FAIL:
      return {
        ...state,
        loading: false,
      }
    /* Delete Bid END */

    /* Post Bid Action */
    case POST_BID_ACTION:
      return {
        ...state,
        loading: true,
      }

    case POST_BID_ACTION_SUCCESS:
    case POST_BID_ACTION_FAIL:
      return {
        ...state,
        loading: false,
      }
    /* Post Bid Action END */

    /* Get Bids */
    case GET_BIDS:
      return {
        ...state,
        loading: true,
      }

    case GET_BIDS_SUCCESS:
      return {
        ...state,
        loading: false,
        bids: action.payload.data,
        totalBids: action.payload.totalCount,
      }

    case GET_BIDS_FAIL:
      return {
        ...state,
        loading: false,
      }
    /* Get Bids END */

    case ITEM_API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Items

/* This example requires Tailwind CSS v2.0+ */
import React, { useState } from "react";
import classNames from "classnames";
import { Collapse, Button, CardBody, Card } from "reactstrap";

const Questions = (props) => {
  const [current, setcurrent] = useState({});

  return (
    <div className="mt-4 mt-sm-0 pt-2 pt-sm-0">
      {/* Single Accordion Item */}
      {props.faqs?.map((item, idx) => {
        return (
          <div key={`fd_${idx}`} className="single-accordion-item p-3">
            {/* Card Header */}
            <div className="card-header bg-inherit border-0 p-0">
              <h2 className="m-0">
                <button
                  className={classNames("btn py-4", {
                    collapsed: !current[item._id],
                  })}
                  onClick={() =>
                    setcurrent((prevState) => ({
                      ...prevState,
                      [item._id]: !prevState[item._id],
                    }))
                  }
                >
                  {item.question}
                </button>
              </h2>
            </div>

            <Collapse isOpen={current[item._id]}>
              <Card>
                <CardBody>
                  <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                </CardBody>
              </Card>
            </Collapse>
          </div>
        );
      })}
    </div>
  );
};

export default Questions;

import React, { useCallback } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import moment from "moment"

// Helpers
import { BLOCKCHAIN_CURRENCY_CODE } from "helpers/constants"

// Actions
import { deleteBid, postBidAction } from "store/actions"

const Offer = (props) => {
  const acceptOffer = useCallback(() => {
    props.onPostBidAction(
      {
        offer_id: props.item._id,
        itemId: props.item.item?._id,
        type: props.item.item?.blockchain,
        actionType: "accept",
      },
      props.onReload,
    )
  }, [props.item._id, props.item.to, props.onReload])

  const rejectOffer = useCallback(() => {
    props.onPostBidAction(
      {
        offer_id: props.item._id,
        itemId: props.item.item?._id,
        type: props.item.item?.blockchain,
        actionType: "cancel",
      },
      props.onReload,
    )
  }, [props.item._id, props.item.to, props.onReload])

  const paymentOffer = useCallback(() => {
    props.onBidPayment(
      { ...props.item.item, price: props.item.price },
      (transactionReceipt) => {
        props.onPostBidAction(
          {
            transactionHash: transactionReceipt.transactionHash,
            offer_id: props.item._id,
            itemId: props.item.item?._id,
            type: props.item.item?.blockchain || "ethereum",
            actionType: "payment",
          },
          props.onReload,
        )
      },
    )
  }, [props.item._id, props.item.to, props.onBidPayment, props.onReload])

  const deleteOffer = useCallback(() => {
    props.onDeleteBid(
      { offer_id: props.item._id, itemId: props.item.item?._id },
      props.onReload,
    )
  }, [props.item._id, props.item.from, props.onReload])

  return (
    <React.Fragment>
      <div className="card activity activity-primary rounded-md shadow p-3">
        <div className="creators creator-primary d-flex align-items-center">
          <div className="position-relative">
            <Link to={`/token/${props.item.item?._id}`}>
              <img
                className="avatar avatar-md-sm shadow-md rounded-pill"
                src={props.item.item?.media?.link || "/images/client/10.jpg"}
                alt=""
              />
            </Link>
          </div>

          <div className="ms-3">
            <h6 className="mb-0">
              {Number(props.item.price).toFixed(8)}{" "}
              {BLOCKCHAIN_CURRENCY_CODE[props.item.item?.blockchain]}{" "}
              <span className="text-muted">by</span>{" "}
              <Link
                to={`/user/${props.item.from?.walletAddress}`}
                className="text-dark name"
              >
                {props.item.from?.name}
              </Link>
            </h6>

            <small className="text-muted mb-2">
              {moment(props.item.created_date).toNow()}
            </small>
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          {props.user._id === props.item.from?._id && (
            <>
              {props.item.status === "accepted" && (
                <Link
                  className="text-dark title mb-0 mx-2 d-block"
                  to={`/token/${props.item.item?._id}`}
                  onClick={(e) => {
                    e.preventDefault()
                    paymentOffer()
                  }}
                >
                  Make Payment
                </Link>
              )}

              <Link
                className="text-dark title mb-0 mx-2 d-block"
                to={`/token/${props.item.item?._id}`}
                onClick={(e) => {
                  e.preventDefault()
                  deleteOffer()
                }}
              >
                Cancel
              </Link>
            </>
          )}

          {props.user._id === props.item.to?._id && (
            <>
              {props.item.status !== "accepted" && (
                <Link
                  className="text-dark title mb-0 mx-2 d-block"
                  to={`/token/${props.item.item?._id}`}
                  onClick={(e) => {
                    e.preventDefault()
                    acceptOffer()
                  }}
                >
                  Accept
                </Link>
              )}

              <Link
                className="text-dark title mb-0 mx-2 d-block"
                to={`/token/${props.item.item?._id}`}
                onClick={(e) => {
                  e.preventDefault()
                  rejectOffer()
                }}
              >
                Reject
              </Link>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ Login }) => ({
  user: Login.user,
})

const mapDispatchToProps = (dispatch) => ({
  onDeleteBid: (data, callback) => dispatch(deleteBid(data, callback)),
  onPostBidAction: (data, callback) => dispatch(postBidAction(data, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Offer)

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";

// Actions
import { getCategories } from "store/actions";

// Constants
import { BLOCKCHAIN_ENUM } from "helpers/constants";

const ORDER_DATA = [
  { orderBy: "_id", value: -1, label: "Newest" },
  { orderBy: "_id", value: 1, label: "Oldest" },
  { orderBy: "price", value: 1, label: "Low to High" },
  { orderBy: "price", value: -1, label: "High to Low" },
];

const STATUS = [
  { label: "Buy Now", fieldName: "status", value: "active" },
  { label: "New", fieldName: "getNew", value: true },
  { label: "Has Offers", fieldName: "has_offer", value: true },
];

const NftFilter = (props) => {
  const [orderBy, setorderBy] = useState();
  const [categoryData, setCategoryData] = useState({});

  const [filter, setfilter] = useState({
    categories: [],
    blockchain: [],
    status: [],
  });

  useEffect(() => {
    props.onGetCategories();
  }, []);

  useEffect(() => {
    setCategoryData(
      props.categories.map((item) => {
        return {
          value: item._id,
          label: item.name,
        };
      })
    );
  }, [props.categories]);

  const handleOrderChange = (data) => {
    setorderBy(data);
    props.setfilter((prevState) => ({
      ...prevState,
      isMore: false,
      orderBy: data.orderBy,
      order: data.value,
    }));
  };

  const onCategoryChange = (data) => {
    setfilter((prevState) => ({ ...prevState, categories: data }));
    props.setfilter((prevState) => ({
      ...prevState,
      isMore: false,
      category: data.map((item) => item.value),
    }));
  };

  const onBlockchainChange = (data) => {
    setfilter((prevState) => ({ ...prevState, blockchain: data }));
    props.setfilter((prevState) => ({
      ...prevState,
      isMore: false,
      blockchain: data.map((item) => item.value),
    }));
  };

  const onStatusChange = (data) => {
    setfilter((prevState) => ({ ...prevState, status: data }));
    props.setfilter((prevState) => ({
      ...prevState,
      ...{
        getNew:
          data.filter((item) => item.fieldName === "getNew")[0]?.value || false,
      },
      fields: data
        .filter((item) => item.fieldName !== "getNew")
        .map((item) => ({
          fieldName: item.fieldName,
          fieldValue: item.value,
        })),
    }));
  };

  return (
    <div className="spinner-content">
      <div className="Select-box">
        <Select
          className="select-style"
          onChange={handleOrderChange}
          options={ORDER_DATA}
          value={orderBy}
          isOptionSelected={(option) =>
            option.orderBy === orderBy?.orderBy &&
            option.value === orderBy?.order
          }
        />

        <Select
          className="select-style"
          options={categoryData}
          isLoading={props.catLoading}
          onChange={onCategoryChange}
          value={filter.categories}
          isMulti
        />

        <Select
          className="select-style"
          onChange={onBlockchainChange}
          options={BLOCKCHAIN_ENUM}
          value={filter.blockchain}
          isMulti
        />

        <Select
          className="select-style"
          onChange={onStatusChange}
          options={STATUS}
          value={filter.status}
          isMulti
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ categories }) => ({
  catLoading: categories.loading,
  categories: categories.categories,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCategories: (data, callback) => dispatch(getCategories(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NftFilter);

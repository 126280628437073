// Get All
export const GET_HOMEPAGE = "GET_HOMEPAGE"
export const GET_HOMEPAGE_SUCCESS = "GET_HOMEPAGE_SUCCESS"
export const GET_HOMEPAGE_FAIL = "GET_HOMEPAGE_FAIL"

// Get Most Viewd
export const GET_MOSTLY_VIEW = "GET_MOSTLY_VIEW"
export const GET_MOSTLY_VIEW_SUCCESS = "GET_MOSTLY_VIEW_SUCCESS"
export const GET_MOSTLY_VIEW_FAIL = "GET_MOSTLY_VIEW_FAIL"

// Get Latest NFT
export const GET_LATEST = "GET_LATEST"
export const GET_LATEST_SUCCESS = "GET_LATEST_SUCCESS"
export const GET_LATEST_FAIL = "GET_LATEST_FAIL"

// Get Top Collections
export const GET_TOP_COLLECTIONS = "GET_TOP_COLLECTIONS"
export const GET_TOP_COLLECTIONS_SUCCESS = "GET_TOP_COLLECTIONS_SUCCESS"
export const GET_TOP_COLLECTIONS_FAIL = "GET_TOP_COLLECTIONS_FAIL"

// Get Explore
export const GET_EXPLORE = "GET_EXPLORE"
export const GET_EXPLORE_SUCCESS = "GET_EXPLORE_SUCCESS"
export const GET_EXPLORE_FAIL = "GET_EXPLORE_FAIL"

import React from "react"
import { Link } from "react-router-dom"
import Slider from "./Slider"

const BestSeller = () => {
  return (
    <section className="section pb-0">
      <div className="container-collection">
        {/* <div className="container"> */}
        <div className="row m-0">
          <div className="col-md-12">
            <div className="section-title">
              <h4 className="title mb-2 PH-font"> Collections</h4>
              <p className="text-muted mb-0 SH-font">
                Best collections of the week's NFTs
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="text-end d-md-block d-none">
            <Link to="/collections" className="btn btn-link primary text-dark">
              See More <i className="uil uil-arrow-right"></i>
            </Link>
          </div>
        </div>
      </div>

      <Slider />

      <div className="row">
        <div className="col">
          <div className="text-center d-md-none d-block">
            <Link to="/collections" className="btn btn-link primary text-dark">
              See More <i className="uil uil-arrow-right"></i>
            </Link>
          </div>
        </div>
      </div>
      {/* </div> */}
    </section>
  )
}

export default BestSeller

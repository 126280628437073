import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Explore from '../components/Explore/ExploreTwo';

const Explore01 = () => {
  return (
    <div>
      <Header />
      <Explore />
      <Footer />
    </div>
  );
}


export default Explore01;

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import toastr from "toastr";
import { Alert, Spinner } from "reactstrap";

// Actions
import {
  postIpfsFile as uploadFile,
  getCollectionNameVerify,
  getCollectionBytecode,
  postCollection,
  getCollection,
  putCollection,
} from "store/actions";

// Hooks
import useWallet from "hooks/wallet";

// util
import Web3Intraction from "util/web3Intraction";

// Constants
import { BLOCKCHAIN_ENUM } from "helpers/constants";

const CreateCollection = (props) => {
  const {
    onUploadFile,
    onGetCollectionNameVerify,
    onGetCollectionBytecode,
    onPostCollection,
    loading,
    error,
  } = props;
  let navigate = useNavigate();
  const { _id } = useParams();
  const { walletType } = useWallet();

  const nameTimeout = useRef();

  const [isCollectionValid, setisCollectionValid] = useState(false);
  const [internalLoading, setinternalLoading] = useState(false);

  const [fields, setfields] = useState({
    name: "",
    description: "",
    image: null,
    imagePreview: null,
    bannerImage: null,
    bannerImagePreview: null,
    blockchain: "ethereum",
  });

  useEffect(() => {
    if (_id && props.userId) {
      props.onGetCollection(_id);
    }
  }, [_id, props.userId]);

  useEffect(() => {
    if (
      _id &&
      props.collection &&
      props.userId === props.collection.author?._id
    ) {
      setfields({
        callectionId: props.collection._id,
        name: props.collection.name,
        description: props.collection.description,
        image: props.collection.image?._id,
        imagePreview: props.collection.image?.link,
        bannerImage: props.collection.bannerImage?._id,
        bannerImagePreview: props.collection.bannerImage?.link,
      });
      setisCollectionValid(true);
    }
  }, [props.collection, props.userId]);

  const handleChange = (name) => (evt) => {
    const { value } = evt.target;

    setfields((prevState) => ({ ...prevState, [name]: value }));
  };

  const onNameChange = (e) => {
    if (_id && e.target.value === props.collection.name) {
      if (nameTimeout.current) {
        clearTimeout(nameTimeout.current);
        nameTimeout.current = null;
      }

      setisCollectionValid(true);
      handleChange("name")(e);
      return;
    }
    if (nameTimeout.current) {
      clearTimeout(nameTimeout.current);
      nameTimeout.current = null;
    }

    nameTimeout.current = setTimeout(() => {
      onGetCollectionNameVerify(e.target.value, (response) => {
        if (response.data.exist) {
          setisCollectionValid(false);
        } else {
          setisCollectionValid(true);
        }
      });
    }, 1000);

    handleChange("name")(e);
  };

  const uploadFileSuccess = (name) => (response) => {
    const { _id } = response.data;

    handleChange(name)({ target: { value: _id } });
  };

  const handleImageChange = (name) => (evt) => {
    const _files = evt.target.files;

    const image = [...(_files || [])].filter((file) => file.size < 101288000);

    if (image && image[0]) {
      onUploadFile({ image: image[0] }, uploadFileSuccess(name));

      setfields((prevState) => ({
        ...prevState,
        ...(name === "bannerImage"
          ? {
              bannerImagePreview: URL.createObjectURL(image[0]),
              bannerImageRaw: image[0],
            }
          : {
              imagePreview: URL.createObjectURL(image[0]),
              imageRaw: image[0],
            }),
      }));
    }
  };

  const onSubmit = async (evt) => {
    evt.preventDefault();

    if (loading) return;
    if (props.fileLoading) return;
    if (!isCollectionValid) return;

    if (_id) {
      props.onPutCollection(fields, () => {
        navigate(`/collection/${props.collection.contractAddress}`);
      });
      return;
    }

    if (!fields.imagePreview) {
      return toastr.error("Collection image is required");
    }

    if (!fields.bannerImagePreview) {
      return toastr.error("Collection banner image is required");
    }

    setinternalLoading(true);

    if (!window.ethereum || props.walletAddress === null) {
      setinternalLoading(false);
      toastr.error(
        "Connect your Metamask wallet to update the message on the blockchain."
      );
      return;
    }

    const web3intraction = new Web3Intraction(
      fields.blockchain,
      walletType,
      props.settings
    );

    if (!web3intraction.isNetworkServiceWorking()) {
      setinternalLoading(false);
      return toastr.error("Service Not Available!");
    }

    const getCollectionData = async (response) => {
      try {
        const { txHash, receipt } = await web3intraction.deployContract(
          props.walletAddress,
          response
        );

        onPostCollection(
          {
            ...fields,
            transactionHash: txHash,
            contractAddress: receipt.contractAddress,
          },
          () => {
            navigate("/my-collections");
          }
        );

        setinternalLoading(false);
      } catch (error) {
        console.log("error", error);
        toastr.error(error);
        setinternalLoading(false);
      }
    };

    web3intraction
      .switchChain()
      .then(() => onGetCollectionBytecode(fields, getCollectionData))
      .catch((error) => {
        if (error?.message) {
          toastr.error(error?.message);
        } else {
          toastr.error(error);
        }

        setinternalLoading(false);
      });
  };

  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12  ">
            <div className="card rounded-md shadow p-4">
              <div className="row">
                <div className="col-lg-7 mt-4 mt-lg-0">
                  <div className="ms-lg-4">
                    <form
                      className="item-form card no-hover spinner-content"
                      onSubmit={onSubmit}
                    >
                      {error && typeof error === "string" ? (
                        <Alert color="danger">{error}</Alert>
                      ) : null}

                      {(internalLoading || loading || props.fileLoading) && (
                        <div className="spinner">
                          <Spinner color="primary" />
                        </div>
                      )}
                      <div className="row">
                        {!_id && (
                          <div className="col-12 mb-4">
                            <label className="form-label fw-bold">
                              Type <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control"
                              placeholder="Blockchain Type"
                              onChange={handleChange("blockchain")}
                              value={fields.blockchain}
                              required
                            >
                              {BLOCKCHAIN_ENUM.map((item, index) => (
                                <option
                                  key={`chain-${index}`}
                                  value={item.value}
                                >
                                  {item.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}

                        <div className="col-12 mb-4">
                          <label className="form-label fw-bold">
                            Collection Name{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={classNames("form-control", {
                              "is-valid": fields.name && isCollectionValid,
                              "is-invalid": fields.name && !isCollectionValid,
                            })}
                            placeholder="Collection Name"
                            onChange={onNameChange}
                            value={fields.name}
                            required
                          />
                        </div>

                        <div className="col-12 mb-4">
                          <label className="form-label fw-bold">
                            Description <span className="text-danger">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            name="textarea"
                            placeholder="Description"
                            cols={30}
                            rows={3}
                            onChange={handleChange("description")}
                            value={fields.description}
                            required
                          />
                        </div>

                        <div className="col-lg-12">
                          <button
                            type="submit"
                            className="btn btn-primary rounded-md"
                          >
                            {_id ? "Update" : "Create"} Collection
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="col-lg-5">
                  {!_id && (
                    <div className="d-grid">
                      <p className="fw-semibold mb-4">
                        Upload your ART here, Please click "Upload Image"
                        Button.
                      </p>
                      <div className="preview-box d-block justify-content-center rounded-md shadow overflow-hidden bg-light text-muted p-2 text-center small">
                        {fields.imageRaw
                          ? fields.imageRaw.name
                          : "Supports JPG, PNG and MP4 videos. Max file size : 100MB."}
                      </div>
                      <input
                        type="file"
                        className="custom-file-input"
                        id="inputGroupFile01"
                        onChange={handleImageChange("image")}
                        accept="image/*"
                        required={!_id}
                        hidden
                      />
                      <label
                        className="btn-upload btn btn-primary rounded-md mt-4"
                        htmlFor="inputGroupFile01"
                      >
                        Upload Image
                      </label>
                    </div>
                  )}

                  <div className="d-grid mt-5">
                    <p className="fw-semibold mb-4">
                      Upload your Banner Image here, Please click "Upload Banner
                      Image" Button.
                    </p>
                    <div className="preview-box d-block justify-content-center rounded-md shadow overflow-hidden bg-light text-muted p-2 text-center small">
                      {fields.bannerImageRaw
                        ? fields.bannerImageRaw.name
                        : "Supports JPG, PNG. Max file size : 100MB."}
                    </div>
                    <input
                      type="file"
                      className="custom-file-input"
                      id="inputGroupFile02"
                      onChange={handleImageChange("bannerImage")}
                      accept="image/*"
                      hidden
                    />
                    <label
                      className="btn-upload btn btn-primary rounded-md mt-4"
                      htmlFor="inputGroupFile02"
                    >
                      Upload Banner Image
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = ({ Login, File, collections }) => ({
  walletAddress: Login.user?.walletAddress,
  userId: Login.user?._id,
  settings: Login.settings,
  fileLoading: File.loading,
  loading: collections.loading,
  error: collections.error,
  collection: collections.collection,
});

const mapDispatchToProps = (dispatch) => ({
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
  onGetCollectionNameVerify: (data, callback) =>
    dispatch(getCollectionNameVerify(data, callback)),
  onGetCollectionBytecode: (data, callback) =>
    dispatch(getCollectionBytecode(data, callback)),
  onPostCollection: (data, callback) =>
    dispatch(postCollection(data, callback)),
  onGetCollection: (id, callback) => dispatch(getCollection(id, callback)),
  onPutCollection: (data, callback) => dispatch(putCollection(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCollection);

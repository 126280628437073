import { call, put, takeEvery } from "redux-saga/effects"

// Category Redux States
import { GET_CATEGORIES } from "./actionTypes"
import { getCategoriesFail, getCategoriesSuccess } from "./actions"

import { getCategories } from "helpers/backend_helper"

function* fetchCategories({ payload }) {
  try {
    const response = yield call(getCategories, payload)

    if (response.status === "failure") {
      return yield put(getCategoriesFail(response.message))
    }

    yield put(getCategoriesSuccess(response))
  } catch (error) {
    yield put(getCategoriesFail(error))
  }
}

function* CategoriesSaga() {
  yield takeEvery(GET_CATEGORIES, fetchCategories)
}

export default CategoriesSaga

import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  error: "",
  loading: false,
  notifications: [],
  totalNotifications: 0,
  category: {},
}

const Notifications = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Notifications */
    case GET_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
      }

    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload.data || [],
        totalNotifications: action.payload.totalCount || 0,
        loading: false,
        error: "",
      }

    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Get Notifications END */

    default:
      return state
  }
}

export default Notifications

import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const Collections = (props) => {
  return (
    <React.Fragment>
      <div className="tiny-slide">
        <div className="card creators creators-two creator-primary rounded-md shadow overflow-hidden mx-2 my-3">
          <Link
            to={`/collection/${props.item.contractAddress}`}
            className="text-dark h6 name d-block mb-0"
          >
            <div
              className="py-5 creator-bg"
              style={{
                background: `url(${
                  props.item.bannerImage?.link || "/images/work/2.jpg"
                })`,
              }}
            ></div>
            <div className="position-relative mt-n5">
              {props.item?.image?.link &&
              props.item.image.mimeType?.includes("video") ? (
                <video
                  src={props.item.image?.link}
                  className="avatar avatar-md-md rounded-pill shadow-sm bg-light img-thumbnail mx-auto d-block"
                  alt=""
                  controls
                />
              ) : (
                <img
                  src={props.item.image?.link}
                  className="avatar avatar-md-md rounded-pill shadow-sm bg-light img-thumbnail mx-auto d-block"
                  alt=""
                />
              )}

              <div className="content text-center pt-2 p-4">
                <p className="text-dark h6 name d-block mb-0">
                  {props.item.name}
                </p>
                <small className="text-muted">{props.item.description}</small>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Collections);

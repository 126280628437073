import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  PUT_PROFILE,
  PUT_PROFILE_IMAGE,
  GET_USER_BY_WALLET,
  /* CHANGE_PASSWORD, */
} from "./actionTypes";
import {
  putProfileSuccess,
  putProfileFail,
  putProfileImageSuccess,
  putProfileImageFail,
  getUserByWalletSuccess,
  getUserByWalletFail,
  // putChangePwdSuccess,
  // putChangePwdError,
} from "./actions";

//Include Both Helper File with needed methods
import {
  putProfile,
  putProfileImage,
  getUserByWallet /* putChangePassword */,
  putProfileApi,
} from "helpers/backend_helper";
import { getCurrentUser } from "store/actions";

function* editProfile({ payload: { data, callback } }) {
  try {
    const response = yield call(putProfileApi, data);
    if (response.status === "failure") {
      return yield put(putProfileFail(response.message));
    }
    yield put(getCurrentUser());
    yield put(putProfileSuccess(response));

    callback && callback(response);
  } catch (error) {
    yield put(putProfileFail("Internal Server Error"));
  }
}

function* editProfileImage({ payload: { data } }) {
  try {
    const response = yield call(putProfileImage, data);
    if (response.status === "failure") {
      return yield put(putProfileImageFail(response.message));
    }

    yield put(getCurrentUser());
    yield put(putProfileImageSuccess(response));
  } catch (error) {
    yield put(putProfileImageFail("Internal Server Error"));
  }
}

/* function* changePassword({ payload: { data, callback } }) {
  try {
    const response = yield call(putChangePassword, data)
    if (response.status === "failure") {
      return yield put(putChangePwdError(response.message))
    }

    callback && callback()
    yield put(putChangePwdSuccess(response))
  } catch (error) {
    yield put(putChangePwdError("Internal Server Error"))
  }
} */

function* onGetUserByWallet({ payload }) {
  try {
    const response = yield call(getUserByWallet, payload);

    if (response.status === "failure") {
      return yield put(getUserByWalletFail(response.message));
    }

    yield put(getUserByWalletSuccess(response.data));
  } catch (error) {
    console.log("error", error);
    yield put(getUserByWalletFail("Internal Server Error"));
  }
}

export function* watchProfile() {
  yield takeEvery(PUT_PROFILE, editProfile);
  yield takeEvery(PUT_PROFILE_IMAGE, editProfileImage);
  yield takeEvery(GET_USER_BY_WALLET, onGetUserByWallet);
  // yield takeEvery(CHANGE_PASSWORD, changePassword)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;

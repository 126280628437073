import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

// Item Redux States
import {
  GET_ITEMS,
  POST_ITEM,
  GET_ITEM_BY_TOKEN,
  POST_ITEM_SALE,
  GET_ACTIVITIES,
  POST_ITEM_BUY,
  GET_ITEM,
  PUT_ITEM,
  POST_TOGGLE_ITEM_SALE,
  POST_BID,
  DELETE_BID,
  POST_BID_ACTION,
  GET_BIDS,
  /* DELETE_ITEM, */
} from "./actionTypes"
import {
  getItemsFail,
  getItemsSuccess,
  postItemFail,
  postItemSuccess,
  getItemFail,
  getItemSuccess,
  postItemSaleFail,
  postItemSaleSuccess,
  getActivitiesFail,
  getActivitiesSuccess,
  postItemBuyFail,
  postItemBuySuccess,
  putItemFail,
  putItemSuccess,
  postToggleItemSaleFail,
  postToggleItemSaleSuccess,
  postBidFail,
  postBidSuccess,
  deleteBidFail,
  deleteBidSuccess,
  postBidActionFail,
  postBidActionSuccess,
  getBidsFail,
  getBidsSuccess,
  /* deleteItemFail,
  deleteItemSuccess, */
} from "./actions"

import {
  getItems,
  postItem,
  getItemByToken,
  postItemSale,
  getActivities,
  postItemBuy,
  getItem,
  putItem,
  postToggleItemSale,
  postBid,
  deleteBid,
  postBidAction,
  getBids,
  /* deleteItem, */
} from "helpers/backend_helper"

function* fetchItems({ payload }) {
  try {
    const response = yield call(getItems, payload)

    if (response.status === "failure") {
      return yield put(getItemsFail(response.message))
    }

    yield put(getItemsSuccess(response))
  } catch (error) {
    yield put(getItemsFail(error))
  }
}

function* onPostItem({ payload: { item, callback } }) {
  try {
    const response = yield call(postItem, item)

    if (response.status == "failure") {
      window.scrollTo(0, 0)

      return yield put(postItemFail(response.message))
    }

    callback && callback()

    yield put(postItemSuccess())
  } catch (error) {
    yield put(postItemFail("Internal Error!"))
  }
}

function* onGetItemByToken({ payload }) {
  try {
    const response = yield call(getItemByToken, payload)

    if (response.status === "failure") {
      return yield put(getItemFail(response.message))
    }

    yield put(getItemSuccess(response))
  } catch (error) {
    yield put(getItemFail(error))
  }
}

function* onPostItemSale({ payload: { data, callback } }) {
  try {
    const response = yield call(postItemSale, data)

    if (response.status === "failure") {
      return yield put(postItemSaleFail(response.message))
    }

    callback && callback(response)

    yield put(postItemSaleSuccess(response.data))
  } catch (error) {
    yield put(postItemSaleFail(error))
  }
}

function* fetchItem({ payload }) {
  try {
    const response = yield call(getItem, payload)

    if (response.status === "failure") {
      return yield put(getItemFail(response.message))
    }

    yield put(getItemSuccess(response))
  } catch (error) {
    console.log("error", error)
    yield put(getItemFail(error))
  }
}

function* onPutItem({ payload: { data, callback } }) {
  try {
    const response = yield call(putItem, data)

    if (response.status === "failure") {
      return yield put(putItemFail(response.message))
    }

    callback && callback(response)

    yield put(putItemSuccess())
  } catch (error) {
    yield put(putItemFail("Internal Error!"))
  }
}

/* function* onDeleteItem({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteItem, data)

    if (response.status == "failure") {
      return yield put(deleteItemFail(response.message))
    }

    yield put(deleteItemSuccess())
    callback && callback()
  } catch (error) {
    yield put(deleteItemFail("Internal Error!"))
  }
} */

function* onGetActivities({ payload: { data, callback } }) {
  try {
    const response = yield call(getActivities, data)

    if (response.status === "failure") {
      return yield put(getActivitiesFail(response.message))
    }

    yield put(getActivitiesSuccess(response))
    callback && callback(response)
  } catch (error) {
    console.log("error", error)
    yield put(getActivitiesFail("Internal Error!"))
  }
}

function* onPostItemBuy({ payload: { data, callback } }) {
  try {
    const response = yield call(postItemBuy, data)

    if (response.status === "failure") {
      return yield put(postItemBuyFail(response.message))
    }

    yield put(postItemBuySuccess(response.data))
    callback && callback(response)
  } catch (error) {
    console.log("error", error)
    yield put(postItemBuyFail("Internal Error!"))
  }
}

function* onPostToggleItemSale({ payload: { data, callback } }) {
  try {
    const response = yield call(postToggleItemSale, data)

    if (response.status === "failure") {
      return yield put(postToggleItemSaleFail(response.message))
    }

    yield put(postToggleItemSaleSuccess(response.data))
    callback && callback(response)
  } catch (error) {
    console.log("error", error)
    yield put()
  }
}

function* onPostBid({ payload: { data, callback } }) {
  try {
    const response = yield call(postBid, data)

    if (response.status === "failure") {
      toastr.error(response.message)
      return yield put(postBidFail(response.message))
    }

    yield put(postBidSuccess(response.data))
    callback && callback()
  } catch (error) {
    console.log("error", error)
    yield put(postBidFail(""))
  }
}

function* onDeleteBid({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteBid, data)

    if (response.status === "failure") {
      return yield put(deleteBidFail(response.message))
    }

    yield put(deleteBidSuccess(response.data))
    callback && callback()
  } catch (error) {
    console.log("error", error)
    yield put(deleteBidFail(""))
  }
}

function* onPostBidAction({ payload: { data, callback } }) {
  try {
    const response = yield call(postBidAction, data)

    if (response.status === "failure") {
      return yield put(postBidActionFail(response.message))
    }

    yield put(postBidActionSuccess(response.data))
    callback && callback()
  } catch (error) {
    console.log("error", error)
    yield put(postBidActionFail(""))
  }
}

function* onGetBids({ payload: { data, callback } }) {
  try {
    const response = yield call(getBids, data)

    if (response.status === "failure") {
      return yield put(getBidsFail(response.message))
    }

    yield put(getBidsSuccess(response))
    callback && callback()
  } catch (error) {
    console.log("error", error)
    yield put(getBidsFail(""))
  }
}

function* ItemsSaga() {
  yield takeEvery(GET_ITEMS, fetchItems)
  yield takeEvery(POST_ITEM, onPostItem)
  yield takeEvery(GET_ITEM_BY_TOKEN, onGetItemByToken)
  yield takeEvery(POST_ITEM_SALE, onPostItemSale)
  yield takeEvery(GET_ACTIVITIES, onGetActivities)
  yield takeEvery(POST_ITEM_BUY, onPostItemBuy)
  yield takeEvery(GET_ITEM, fetchItem)
  yield takeEvery(PUT_ITEM, onPutItem)
  yield takeEvery(POST_TOGGLE_ITEM_SALE, onPostToggleItemSale)
  yield takeEvery(POST_BID, onPostBid)
  yield takeEvery(DELETE_BID, onDeleteBid)
  yield takeEvery(POST_BID_ACTION, onPostBidAction)
  yield takeEvery(GET_BIDS, onGetBids)
  // yield takeEvery(DELETE_ITEM, onDeleteItem)
}

export default ItemsSaga

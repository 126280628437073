import React, { useEffect } from "react"
import { connect } from "react-redux"

// Components
import Header from "../components/header/Header"
import Footer from "../components/footer/Footer"
import Activities from "../components/Activities/Activities"

// Actions
import { getHomepage } from "store/actions"

const Activity = (props) => {
  useEffect(() => {
    props.onGetHomepage()
  }, [])

  return (
    <React.Fragment>
      <Header />

      <Activities />

      <Footer />
    </React.Fragment>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  onGetHomepage: (data, callback) => dispatch(getHomepage(data, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Activity)

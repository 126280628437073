import React from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import toastr from "toastr"

const Header = (props) => {
  const copyToClipboard = (text) => {
    var textarea = document.createElement("textarea")
    textarea.textContent = text
    textarea.style.position = "fixed" // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea)
    textarea.select()
    try {
      document.execCommand("copy") // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn("Copy to clipboard failed.", ex)
      // return prompt("Copy to clipboard: Ctrl+C, Enter", text);
    } finally {
      document.body.removeChild(textarea)
    }

    toastr.success("Copied to clipboard!")
  }

  return (
    <React.Fragment>
      <div className="dropdown dropdown-primary">
        <button
          type="button"
          onClick={props.avatarHandler}
          className={
            "btn btn-pills dropdown-toggle p-0 HH" +
            (props.openDropdown.avatar ? " show" : "")
          }
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={props.openDropdown.avatar}
        >
          <img
            src={props.user?.profileImage?.link || "/images/client/01.jpg"}
            className="rounded-pill avatar avatar-md-sm img-thumbnail shadow-md"
            alt=""
          />
        </button>

        <div
          className={
            "dropdown-menu dd-menu dropdown-menu-end bg-white shadow border-0 mt-3 pb-3 pt-0 overflow-hidden rounded" +
            (props.openDropdown.avatar ? " show" : "")
          }
          style={
            props.openDropdown.avatar
              ? {
                  minWidth: "200px",
                  position: "absolute",
                  inset: "0px 0px auto auto",
                  margin: "0px",
                  transform: "translate(0px, 40px)",
                }
              : { minWidth: "200px" }
          }
        >
          <div className="position-relative">
            <div className="pt-4 pb-3 bg-gradient-primary"></div>
            <div className="px-3">
              <div className="d-flex align-items-end mt-n4">
                <img
                  src={
                    props.user?.profileImage?.link || "/images/client/01.jpg"
                  }
                  className="rounded-pill avatar avatar-md-sm img-thumbnail shadow-md"
                  alt=""
                />
                <h6 className="text-dark fw-bold mb-0 ms-1">
                  {props.user?.name}
                </h6>
              </div>
              {props.user?.publicKey && (
                <div className="mt-2">
                  <small className="text-start text-dark d-block fw-bold ">
                    Wallet:
                  </small>
                  <div className="d-flex justify-content-between align-items-center">
                    <small id="myPublicAddress" className="text-muted">
                      {props.user?.publicKey?.toString()?.slice(0, 8)}
                      ...
                      {props.user?.publicKey?.toString()?.slice(-8)}
                      {/* {props.authToken?.toString()?.slice(0, 20)}... */}
                    </small>
                    <a
                      href=""
                      className="text-primary"
                      onClick={(e) => {
                        e.preventDefault()
                        copyToClipboard(props.user?.publicKey)
                      }}
                    >
                      <span className="uil uil-copy"></span>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mx-2 mt-2">
            <Link
              to={`/user/${props.user?.walletAddress}`}
              className="dropdown-item small fw-semibold text-dark d-flex align-items-center"
            >
              <span className="mb-0 d-inline-block me-1">
                <i className="uil uil-user align-middle h6 mb-0 me-1"></i>
              </span>{" "}
              Profile
            </Link>
            <Link
              to={`/edit-profile`}
              className="dropdown-item small fw-semibold text-dark d-flex align-items-center"
            >
              <span className="mb-0 d-inline-block me-1">
                <i className="uil uil-user align-middle h6 mb-0 me-1"></i>
              </span>{" "}
              Edit Profile
            </Link>

            {/* <Link
              to="/edit-profile"
              className="dropdown-item small fw-semibold text-dark d-flex align-items-center"
            >
              <span className="mb-0 d-inline-block me-1">
                <i className="uil uil-cog align-middle h6 mb-0 me-1"></i>
              </span>{" "}
              Settings
            </Link> */}

            <Link
              to="/my-collections"
              className="dropdown-item small fw-semibold text-dark d-flex align-items-center"
            >
              <span className="mb-0 d-inline-block me-1">
                <i className="uil uil-edit align-middle h6 mb-0 me-1"></i>
              </span>{" "}
              My Collections
            </Link>

            <div className="dropdown-divider border-top"></div>

            <Link
              to="/logout"
              className="dropdown-item small fw-semibold text-dark d-flex align-items-center"
            >
              <span className="mb-0 d-inline-block me-1">
                <i className="uil uil-sign-out-alt align-middle h6 mb-0 me-1"></i>
              </span>{" "}
              Logout
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ Login }) => ({
  user: Login.user,
})

export default connect(mapStateToProps)(Header)

import {
  GET_FAQS,
  GET_FAQS_FAIL,
  GET_FAQS_SUCCESS,
  POST_CONTACT_US,
  POST_CONTACT_US_FAIL,
  POST_CONTACT_US_SUCCESS,
  GET_CONTENT_PAGE,
  GET_CONTENT_PAGE_FAIL,
  GET_CONTENT_PAGE_SUCCESS,
} from "./actionTypes"

/* Get Faqs */
export const getFaqs = (data, callback) => ({
  type: GET_FAQS,
  payload: { data, callback },
})

export const getFaqsFail = (error) => ({
  type: GET_FAQS_FAIL,
  payload: error,
})

export const getFaqsSuccess = (data) => ({
  type: GET_FAQS_SUCCESS,
  payload: data,
})
/* Get Faqs END */

/* Post ContactUs */
export const postContactUs = (data, callback) => ({
  type: POST_CONTACT_US,
  payload: { data, callback },
})

export const postContactUsFail = (error) => ({
  type: POST_CONTACT_US_FAIL,
  payload: error,
})

export const postContactUsSuccess = (data) => ({
  type: POST_CONTACT_US_SUCCESS,
  payload: data,
})
/* Post ContactUs END */

/* Get Content Pages */
export const getContentPage = (type) => ({
  type: GET_CONTENT_PAGE,
  payload: type,
})

export const getContentPageFail = (error) => ({
  type: GET_CONTENT_PAGE_FAIL,
  payload: error,
})

export const getContentPageSuccess = (data) => ({
  type: GET_CONTENT_PAGE_SUCCESS,
  payload: data,
})
/* Get Content Pages END */

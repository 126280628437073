import { combineReducers } from "redux";

// Alert
import Alert from "./Alert/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Register from "./auth/register/reducer";
import Profile from "./auth/profile/reducer";

// Home
import home from "./home/reducer";

// Files
import File from "./File/reducer";

// Collections
import collections from "./collection/reducer";

// Categories
import categories from "./category/reducer";

// items
import items from "./items/reducer";

//Notifications
import notifications from "./notification/reducer";
// Content Pages
import contentpage from "./contentpage/reducer";

// Faq
import faq from "./faqs/reducer";

const rootReducer = combineReducers({
  // public
  Alert,
  Login,
  Register,
  Profile,
  File,
  home,
  collections,
  categories,
  items,
  notifications,
  contentpage,
  faq,
});

export default rootReducer;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";

// Hooks
import useWallet from "hooks/wallet";

// Actions
import { loginUser } from "store/actions";

// Helpers
import { BLOCKCHAIN_ENUM, WALLETS } from "helpers/constants";

// Web3
import Web3Intraction from "util/web3Intraction";

const WalletMidSection = (props) => {
  const { connect, isActive, account } = useWallet();
  const navigate = useNavigate();

  const [activeTab, setactiveTab] = useState("ethereum");
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (isActive) {
      props.onLoginUser({ walletAddress: account }, navigate);
    }
  }, [isActive]);

  const onConnect = (walletType) => async (e) => {
    e.preventDefault();
    setloading(true);
    await connect(walletType);

    const web3Intraction = new Web3Intraction(
      activeTab,
      walletType,
      props.settings
    );
    if (walletType === "metamask")
      web3Intraction.switchChain().then(console.log).catch(console.log);
  };

  return (
    <section className="account py-0">
      <div className="container-fluid ps-0">
        <div className="row">
          <div className="col-lg-4 col-md-4 px-0 account-img-wrap">
            <div className="inner-div position-relative h-100">
              <img
                src="images/bg/04.jpg"
                alt=""
                className="img-fluid w-100 h-100"
              />
            </div>
          </div>

          <div className="col-lg-6 offset-lg-1 col-md-6 p-md-5 p-lg-0 p-3">
            <div className="content-inner h-100 d-flex flex-column justify-content-center">
              <h4>Sign In</h4>
              <p className="text-muted">
                Choose one of available wallet providers or create a new wallet.{" "}
                <a
                  href="https://docs.ethhub.io/using-ethereum/wallets/intro-to-ethereum-wallets"
                  className=""
                >
                  What is a wallet?
                </a>{" "}
              </p>

              <ul
                className="nav nav-pills mt-3 bg-transparent"
                id="pills-tab"
                role="tablist"
              >
                {BLOCKCHAIN_ENUM.map((item, index) => (
                  <li
                    key={`blockchain-${index}`}
                    className="nav-item"
                    role="presentation"
                  >
                    <button
                      className={classNames("nav-link px-3", {
                        active: activeTab === item.value,
                      })}
                      onClick={() => setactiveTab(item.value)}
                    >
                      <img src={item.logo} alt="" className="img-fluid" />
                      {item.label}
                    </button>
                  </li>
                ))}
              </ul>

              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active w-50"
                  id="pills-Poligon"
                  role="tabpanel"
                  aria-labelledby="pills-Poligon-tab"
                >
                  {WALLETS.filter((item) =>
                    item.validChain.includes(activeTab)
                  ).map((item) => (
                    <div key={`wallet-${item.value}`} className="pt-3">
                      <button
                        className="btn d-flex align-items-center justify-content-start w-100 rounded active"
                        onClick={onConnect(item.value)}
                      >
                        <img src={item.logo} alt="" className="img-fluid" />
                        <span className="px-2">{item.label}</span>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = ({ Login }) => ({
  loading: Login.loading,
  settings: Login.settings,
});

const mapDispatchToProps = (dispatch) => ({
  onLoginUser: (data, callback) => dispatch(loginUser(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletMidSection);

import React from "react"
import { connect } from "react-redux"
import { Spinner } from "reactstrap"

//
import Item from "components/UserProfile/Items/Item"

const RelatedAuctionDetail = (props) => {
  if (
    !props.itemDetails?.relatedItems ||
    props.itemDetails?.relatedItems?.length <= 0
  )
    return <></>

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col">
          <div className="section-title text-center mb-4 pb-2">
            <h4 className="title mb-4">Related Items</h4>
            <p className="text-muted para-desc mb-0 mx-auto">
              We are a huge marketplace dedicated to connecting great artists of
              all Artspray with their fans and unique token collectors!
            </p>
          </div>
        </div>
      </div>

      <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1 spinner-content">
        {props.loading && (
          <div className="spinner">
            <Spinner color="primary" />
          </div>
        )}

        {props.itemDetails.relatedItems.map((item, idx) => (
          <div key={`exo_${idx}`} className="col mt-4 pt-2">
            <Item item={item} />
          </div>
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = ({ items }) => ({
  itemDetails: items.itemDetails,
  loading: items.loading,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RelatedAuctionDetail)

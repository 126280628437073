import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  GET_CURRENT_USER,
  GET_CURRENT_USER_FAIL,
  GET_CURRENT_USER_SUCCESS,
  GET_SETTINGS,
  GET_SETTINGS_FAIL,
  GET_SETTINGS_SUCCESS,
} from "./actionTypes"

export const loginUser = (user, navigate) => {
  return {
    type: LOGIN_USER,
    payload: { user, navigate },
  }
}

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = (navigate, role) => {
  return {
    type: LOGOUT_USER,
    payload: { navigate, role },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const getCurrentUser = () => ({
  type: GET_CURRENT_USER,
})

export const getCurrentUserSuccess = (user) => ({
  type: GET_CURRENT_USER_SUCCESS,
  payload: user,
})

export const getCurrentUserFail = (error) => ({
  type: GET_CURRENT_USER_FAIL,
  payload: error,
})

export const getSettings = () => ({
  type: GET_SETTINGS,
})

export const getSettingsSuccess = (setting) => ({
  type: GET_SETTINGS_SUCCESS,
  payload: setting,
})

export const getSettingsFail = () => ({
  type: GET_SETTINGS_FAIL,
})

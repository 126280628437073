import { call, put, takeEvery } from "redux-saga/effects"

// Category Redux States
import { GET_NOTIFICATIONS } from "./actionTypes"
import { getNotificationsFail, getNotificationsSuccess } from "./actions"

import { getNotifications } from "helpers/backend_helper"

function* fetchNotifications({ payload }) {
  try {
    const response = yield call(getNotifications, payload)

    if (response.status === "failure") {
      return yield put(getNotificationsFail(response.message))
    }

    yield put(getNotificationsSuccess(response))
  } catch (error) {
    yield put(getNotificationsFail(error))
  }
}

function* NotificationsSaga() {
  yield takeEvery(GET_NOTIFICATIONS, fetchNotifications)
}

export default NotificationsSaga

import React, { useEffect } from "react"
import { connect } from "react-redux"

//
import { getContentPage } from "store/actions"

const MidSection = (props) => {
  useEffect(() => {
    props.onGetContentPage(props.type)
  }, [props.type])

  return (
    <React.Fragment>
      <section className="section mt-5">
        <div className="container clearfix">
          <div dangerouslySetInnerHTML={{ __html: props.content }} />
        </div>
      </section>
    </React.Fragment>
  )
}

const mapStateToProps = ({ contentpage }) => ({
  content: contentpage.content,
})

const mapDispatchToProps = (dispatch) => ({
  onGetContentPage: (type) => dispatch(getContentPage(type)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MidSection)

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "reactstrap";
import toastr from "toastr";

// Actions
import { postContactUs } from "store/actions"

const ContactSection = (props) => {
  const { supportSetting } = useSelector(
    (state) => state.Login?.settings || {}
  );
  const { loading } = useSelector(
    (state) => state.contentpage || {}
  );
  const dispatch = useDispatch()

  const [fields, setfields] = useState({})

  const handleChange = (name) => (evt) => {
    const { value } = evt.target

    setfields((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = (evt) => {
    evt.preventDefault()

    if (loading) return

    dispatch(postContactUs(fields, () => {
      toastr.success("Success")
      setfields({})
    }))
  }

  return (
    <React.Fragment>
      <section className="section mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="card border-0 text-center features feature-primary feature-clean">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-phone d-block rounded-md h3 mb-0"></i>
                </div>
                <div className="content mt-4 px-4">
                  <h5 className="fw-bold">Phone</h5>
                  <p className="text-muted">
                    Start working with Artspray that can provide everything
                  </p>
                  <a
                    href={`tel:${supportSetting?.mobileNumber}`}
                    className="text-primary"
                  >
                    {supportSetting?.mobileNumber}
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="card border-0 text-center features feature-primary feature-clean">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-envelope d-block rounded-md h3 mb-0"></i>
                </div>
                <div className="content mt-4 px-4">
                  <h5 className="fw-bold">Email</h5>
                  <p className="text-muted">
                    Start working with Artspray that can provide everything
                  </p>
                  <a
                    href={`mailto:${supportSetting?.email}`}
                    className="text-primary"
                  >
                    {supportSetting?.email}
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
              <div className="card border-0 text-center features feature-primary feature-clean">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-map-marker d-block rounded-md h3 mb-0"></i>
                </div>
                <div className="content mt-4 px-4">
                  <h5 className="fw-bold">Location</h5>
                  <p className="text-muted">{supportSetting?.address}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5 mt-60">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">
              <div className="card shadow rounded border-0">
                <div className="card-body py-5">
                  <h4 className="card-title PH-font">Get In Touch !</h4>
                  <div className="custom-form mt-3">
                    <form
                      name="myForm"
                      onSubmit={handleSubmit}
                    >
                      {loading && (
                        <div className="spinner">
                          <Spinner color="primary" />
                        </div>
                      )}

                      <p className="mb-0" id="error-msg"></p>
                      <div id="simple-msg"></div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Your Name <span className="text-danger">*</span>
                            </label>
                            <input
                              name="name"
                              id="name"
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Name :"
                              onChange={handleChange("name")}
                              value={fields.name}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Your Email <span className="text-danger">*</span>
                            </label>
                            <input
                              name="email"
                              id="email"
                              type="email"
                              className="form-control"
                              placeholder="Enter Your Email :"
                              onChange={handleChange("email")}
                              value={fields.email}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label">Subject <span className="text-danger">*</span></label>
                            <input
                              name="subject"
                              id="subject"
                              className="form-control"
                              placeholder="Type Subject :"
                              onChange={handleChange("subject")}
                              value={fields.subject}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label">
                              Comments <span className="text-danger">*</span>
                            </label>
                            <textarea
                              name="comments"
                              id="comments"
                              rows="4"
                              className="form-control"
                              placeholder="Enter Your Message :"
                              onChange={handleChange("message")}
                              value={fields.message}
                              required
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="d-grid">
                            <button
                              type="submit"
                              id="submit"
                              name="send"
                              className="btn btn-primary rounded-md"
                            >
                              Send Message
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-7 col-md-6 order-1 order-md-2">
              <div className="card border-0">
                <div className="card-body p-0">
                  <img
                    src="images/svg/office-desk.svg"
                    className="img-fluid d-block mx-auto"
                    style={{ maxWidth: "550px" }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ContactSection

import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";

const Pages404 = () => {
  return (
    <div className="main">
      <Header />

      <div className="account-pages my-5 pt-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1 className="display-2 font-weight-medium">404</h1>
                <h4 className="text-uppercase">Sorry, page not found</h4>
                <div className="mt-5 text-center">
                  <Link className="btn-404" to="/">
                    Back to Home
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src="/img/error-img.png" alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </div>
  );
};

export default Pages404;

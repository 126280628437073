import { post, get, put } from "./api_helper";
import * as url from "./url_helper";
import authHeader from "./jwt-token-access/auth-token-header";

// Login Method
export const postLogin = (data) => post(url.POST_LOGIN, data);
export const getSettings = () => get(url.GET_SETTINGS);

// Register
export const postRegister = (data) => post(url.POST_REGISTER, data);

// File
export const postFile = (data) => {
  const formData = new FormData();

  Object.entries(data).map(([key, value]) => formData.append(key, value));

  const config = {
    headers: { ...authHeader(), "content-type": "multipart/form-data" },
  };

  return post(url.POST_FILE, formData, config);
};

export const postIpfsFile = (data) => {
  const formData = new FormData();

  Object.entries(data).map(([key, value]) => formData.append(key, value));

  const config = {
    headers: { ...authHeader(), "content-type": "multipart/form-data" },
  };

  return post(url.POST_IPFS_FILE, formData, config);
};

// User
export const getCurrentUser = () => get(url.GET_CURRENT_USER);
export const putProfileApi = (data) => post(url.PUT_PROFILE, data);
export const putProfileImage = (data) => {
  const formData = new FormData();

  Object.entries(data).map(([key, value]) => formData.append(key, value));

  const config = {
    headers: { ...authHeader(), "content-type": "multipart/form-data" },
  };

  return post(url.PUT_PROFILE_IMAGE, formData, config);
};
export const getUserByWallet = (walletAddress) =>
  get(url.GET_USER_BY_WALLET + walletAddress);

// Homepage
export const getHomepage = (data) => post(url.GET_HOMEPAGE, data);
export const getMostlyView = (data) => post(url.GET_MOSTLY_VIEW, data);
export const getLatest = (data) => post(url.GET_LATEST, data);
export const getTopCollections = (data) => post(url.GET_TOP_COLLECTIONS, data);
export const getExplore = (data) => post(url.GET_EXPLORE, data);

// Collection
export const getCollectionNameVerify = (name) =>
  get(url.GET_COLLECTION_NAME_VERIFY + name);
export const getCollectionBytecode = (data) =>
  post(url.GET_COLLECTION_BYTECODE, data);
export const getCollections = (data) => post(url.GET_COLLECTIONS, data);
export const postCollection = (data) => post(url.POST_COLLECTION, data);
export const getCollection = (_id) => get(url.GET_COLLECTION + _id);
export const getCollectionByAddress = (_id) =>
  get(url.GET_COLLECTION_BY_ADDRESS + _id);
export const putCollection = (data) => post(url.PUT_COLLECTION, data);

// Category
export const getCategories = (data) => post(url.GET_CATEGORIES, data);

// Item
export const getItems = (data) => post(url.GET_ITEMS, data);
export const postItem = (data) => post(url.POST_ITEM, data);
export const putItem = (data) => put(url.PUT_ITEM, data);
export const getItemByToken = (token) => get(url.GET_ITEM_BY_TOKEN + token);
export const postItemSale = (data) => post(url.POST_ITEM_SALE, data);
export const getActivities = (data) => post(url.GET_ACTIVITIES, data);
export const postItemBuy = (data) => post(url.POST_ITEM_BUY, data);
export const getItem = (id) => get(url.GET_ITEM + id);
export const postToggleItemSale = (data) =>
  post(url.POST_TOGGLE_ITEM_SALE, data);
/* Bids */
export const postBid = (data) => post(url.POST_BID, data);
export const deleteBid = (data) => post(url.DELETE_BID, data);
export const postBidAction = (data) => post(url.POST_BID_ACTION, data);
export const getBids = (data) => post(url.GET_BIDS, data);

// Faq
export const getFaqs = () => get(url.GET_FAQS);

// Content Pages
export const getContentPage = (type) =>
  get(url.GET_CONTENT_PAGE + "?type=" + type);

// Contact Us
export const postContactUs = (data) => post(url.POST_CONTACT_US, data);

export const getNotifications = (data) => post(url.GET_NOTIFICATIONS, data);

export const getPrices = () => get(url.GET_PRICES);

import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

// Comp
import Collection from "components/UserProfile/Collections/Collection"

const Slider = () => {
  const { topCollections } = useSelector((state) => state.home)

  return (
    <div className="row">
      {topCollections.map((item) => (
        <div key={item._id} className="col-12 mt-3">
          <Collection item={item} />
        </div>
      ))}
    </div>
  )
}

export default Slider

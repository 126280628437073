import React, { useEffect } from "react"
import { connect } from "react-redux"

// Components
import Header from "../components/header/Header"
import Footer from "../components/footer/Footer"
import SubHeader from "../components/subHeader/SubHeader"
import BestSeller from "../components/bestSeller/BestSeller"

// Actions
import { getHomepage } from "store/actions"

const Home01 = (props) => {
  useEffect(() => {
    props.onGetHomepage()
  }, [])

  return (
    <React.Fragment>
      <Header />
      <SubHeader />
      <BestSeller />

      <Footer />
    </React.Fragment>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  onGetHomepage: (data, callback) => dispatch(getHomepage(data, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Home01)

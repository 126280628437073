import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  PUT_PROFILE,
  PUT_PROFILE_FAIL,
  PUT_PROFILE_SUCCESS,
  PUT_PROFILE_IMAGE,
  PUT_PROFILE_IMAGE_FAIL,
  PUT_PROFILE_IMAGE_SUCCESS,
  GET_USER_BY_WALLET,
  GET_USER_BY_WALLET_FAIL,
  GET_USER_BY_WALLET_SUCCESS,
} from "./actionTypes";

export const editProfile = (user, navigate) => {
  return {
    type: EDIT_PROFILE,
    payload: { user, navigate },
  };
};

export const profileSuccess = (msg) => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  };
};

export const profileError = (error) => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  };
};

export const putChangePwd = (data, callback) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { data, callback },
  };
};

export const putChangePwdSuccess = (msg) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: msg,
  };
};

export const putChangePwdError = (error) => {
  return {
    type: CHANGE_PASSWORD_FAIL,
    payload: error,
  };
};

export const putProfile = (data, callback) => ({
  type: PUT_PROFILE,
  payload: { data, callback },
});

export const putProfileSuccess = (data) => ({
  type: PUT_PROFILE_SUCCESS,
  payload: data,
});

export const putProfileFail = (error) => ({
  type: PUT_PROFILE_FAIL,
  payload: error,
});

export const putProfileImage = (data, callback) => ({
  type: PUT_PROFILE_IMAGE,
  payload: { data, callback },
});

export const putProfileImageSuccess = (data) => ({
  type: PUT_PROFILE_IMAGE_SUCCESS,
  payload: data,
});

export const putProfileImageFail = (error) => ({
  type: PUT_PROFILE_IMAGE_FAIL,
  payload: error,
});

export const getUserByWallet = (walletAddress) => ({
  type: GET_USER_BY_WALLET,
  payload: walletAddress,
});

export const getUserByWalletSuccess = (data) => ({
  type: GET_USER_BY_WALLET_SUCCESS,
  payload: data,
});

export const getUserByWalletFail = (error) => ({
  type: GET_USER_BY_WALLET_FAIL,
  payload: error,
});

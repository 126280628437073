import React from "react"
import { useNavigate, useLocation } from "react-router-dom"
import classNames from "classnames"

const Tabs = (props) => {
  const navigate = useNavigate()
  const location = useLocation()

  const onTabChange = (item) => (evt) => {
    navigate(`${location.pathname}?tab=${item.key}`)
  }

  return (
    <ul className="nav nav-tabs border-bottom" id="myTab" role="tablist">
      {props.TABS?.map((item, idx) => (
        <li key={`tab-${idx}`} className="nav-item" role="presentation">
          <button
            className={classNames("nav-link", {
              active: props.currenttab === item.key,
            })}
            onClick={onTabChange(item)}
            type="button"
            role="tab"
            aria-selected={props.currenttab === item.key}
          >
            {item.label}
          </button>
        </li>
      ))}
    </ul>
  )
}

export default Tabs

import AboutUs from "./AboutUs";
import HowTo from "./HowTo";
import Blog from "./Blog";
import Contact from "./Contact";
import EditProfile from "./EditProfile";
import Explore from "./Explore";
import Home01 from "./Home01";
import ItemDetail from "./ItemDetail";
import PrivacyPolicy from "./PrivacyPolicy";
import UserProfile from "./Profile";
import Terms from "./Terms";
import Wallet from "./Wallet";
import MyCollections from "./Collections";
import Logout from "./logout";
import Pages404 from "./pages-404";
import CreateCollections from "./CreateCollection";
import CreateItem from "./CreateItem";
import CollectionProfile from "./CollectionProfile";
import TopCollections from "./TopCollections";
import Activity from "./Activity";
import FAQ from "./Faq";

export const authRoutes = [{ path: "/wallet", component: <Wallet /> }];

export const publicRoutes = [
  { path: "/", component: <Home01 /> },
  { path: "/explore", component: <Explore /> },
  // {path: '/activity', component: <Create />},
  { path: "/token/:token_uri_id", component: <ItemDetail /> },
  { path: "/collection/:address", component: <CollectionProfile /> },
  { path: "/about", component: <AboutUs /> },
  { path: "/how-to", component: <HowTo /> },
  { path: "/activities", component: <Activity /> },
  { path: "/create", component: <CreateItem /> },
  { path: "/user/:walletOrUsername", component: <UserProfile /> },
  { path: "/terms", component: <Terms /> },
  { path: "/faq", component: <FAQ /> },
  { path: "/contact", component: <Contact /> },
  { path: "/privacy", component: <PrivacyPolicy /> },
  { path: "/collections", component: <TopCollections /> },
  { path: "*", component: <Pages404 /> },
];

export const userRoutes = [
  { path: "/edit-profile", component: <EditProfile /> },
  { path: "/edit-item/:_id", component: <CreateItem /> },
  { path: "/my-collections", component: <MyCollections /> },
  { path: "/create-collection", component: <CreateCollections /> },
  { path: "/edit-collection/:_id", component: <CreateCollections /> },
  { path: "/logout", component: <Logout /> },
];

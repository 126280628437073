import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";

// Create a connector
const connector = new WalletConnect({
  bridge: "https://bridge.walletconnect.org", // Required
  qrcodeModal: QRCodeModal,
});

export const connectWallet = async () => {
  return new Promise((resolve) => {
    try {
      // Check if connection is already established
      if (!connector.connected) {
        // create new session
        connector.createSession();
      }

      connector.on("connect", (error, payload) => {
        if (error) {
          console.log("error", error);
          resolve({ address: "", chainId: "" });
        } else {
          // Get provided accounts and chainId
          const { accounts, chainId } = payload.params[0];
          resolve({ address: accounts[0], chainId });
        }
      });
    } catch (err) {
      console.log("err", err);
      resolve({ address: "", chainId: "" });
    }
  });
};

export const getCurrentWalletConnected = async () => {
  try {
    return { address: connector.accounts[0] };
  } catch (err) {
    return { address: "" };
  }
};

export const getCurrentChain = () => {
  try {
    return connector.chainId;
  } catch (err) {
    return "";
  }
};

export const onSessionUpdate = (callback) => {
  try {
    connector.on("session_update", (error, payload) => {
      if (error) {
        throw error;
      }

      // Get updated accounts and chainId
      const { accounts, chainId } = payload.params[0];
      callback({ accounts, chainId });
    });
  } catch (err) {}
};

export const sendTransaction = (params) => {
  try {
    return connector.sendTransaction(params);
  } catch (err) {}
};

export const switchChain = (params) => {
  return connector.updateChain(params);
};

export const addChain = (params) => {
  return connector.updateChain(params);
};

export const disconnect = () => {
  connector.killSession();
};

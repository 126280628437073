import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Alert, Spinner } from "reactstrap";
import toastr from "toastr";
import { AiOutlineCamera } from "react-icons/ai";
import validator from "validator";
import classNames from "classnames";

// Actions
import { uploadFile, putProfile } from "store/actions";

const validatePhoneNumber = (number) => {
  const isValidPhoneNumber = validator.isMobilePhone(number, "any", {
    strictMode: true,
  });
  return isValidPhoneNumber;
};

const ProfileEdit = (props) => {
  const { onEditUser, onUploadFile, loading, error } = props;

  const [fields, setfields] = useState({
    name: "",
    email: "",
    bio: "",
    countryCode: "+91",
    mobileNumber: "",
    profileImage: null,
    bannerImage: null,
  });
  const [images, setimages] = useState({
    profileImage: null,
    bannerImage: null,
  });

  useEffect(() => {
    if (props.user) {
      setfields((prevState) => ({
        ...prevState,
        name: props.user.name,
        email: props.user.email,
        bio: props.user.bio,
        mobileNumber: props.user.mobileNumber,
        profileImage: props.user.profileImage?._id,
        bannerImage: props.user.bannerImage?._id,
      }));

      setimages({
        profileImage: props.user.profileImage?.link,
        bannerImage: props.user.bannerImage?.link,
      });
    }
  }, [props.user]);

  const handleChange = (name) => (evt) => {
    const { value } = evt.target;

    setfields((prevState) => ({ ...prevState, [name]: value }));
  };

  const uploadFileSuccess = (name) => (response) => {
    const { _id } = response.data;

    handleChange(name)({ target: { value: _id } });
  };

  const handleImageChange = (name) => (evt) => {
    const _files = evt.target.files;

    const image = [...(_files || [])].filter((file) => file.size < 101288000);

    if (image.length < _files.length) {
      return toastr.error(props.t("max_file_size"));
    }

    if (image && image[0]) {
      onUploadFile({ image: image[0] }, uploadFileSuccess(name));

      setimages((prevState) => ({
        ...prevState,
        [name]: URL.createObjectURL(image[0]),
      }));
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (loading) return;
    if (!validatePhoneNumber(fields.mobileNumber)) return;
    const callback = (response) => {
      toastr.success(response.message);
    };

    const { email, ...rest } = fields;

    if (email !== props.user?.email) {
      rest.email = email;
    }
    console.log(rest, "REST");
    onEditUser(rest, callback);
  };

  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col-lg-9 text-pf">
            <h5>
              You can set preferred display name, create your branded profile
              URL and manage other personal settings.
            </h5>
          </div>
        </div>

        <form
          className="item-form card no-hover spinner-content needs-validation"
          onSubmit={handleSubmit}
          novalidate
        >
          {error && typeof error === "string" ? (
            <Alert color="danger">{error}</Alert>
          ) : null}

          {loading && (
            <div className="spinner">
              <Spinner color="primary" />
            </div>
          )}

          <div className="row">
            <div className="col-lg-8 col-md-7 col-12 order-2 order-md-1 mt-4 pt-2">
              <div className="rounded-md shadow">
                <div className="p-4 border-bottom">
                  <h5 className="mb-0">Edit Profile :</h5>
                </div>

                <div className="p-4">
                  <div className="profile-edit">
                    <div className="row">
                      <div className="col-12 mb-4">
                        <label className="form-label h6">Display Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={fields.name}
                          onChange={handleChange("name")}
                          placeholder="Enter your Name"
                          required="required"
                        />
                      </div>

                      <div className="col-12 mb-4">
                        <label className="form-label h6">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={fields.email}
                          onChange={handleChange("email")}
                          placeholder="Enter your Email"
                          required="required"
                        />
                      </div>

                      <div className="col-12 mb-4">
                        <label className="form-label h6">Mobile Number</label>
                        <input
                          type="phone"
                          className={classNames("form-control", {
                            "is-invalid":
                              fields.mobileNumber &&
                              !validatePhoneNumber(fields.mobileNumber),
                          })}
                          name="mobileNumber"
                          value={fields.mobileNumber}
                          onChange={handleChange("mobileNumber")}
                          placeholder="+19999999999"
                          required
                        />
                        <div className="invalid-feedback">
                          Please enter valid mobile number
                        </div>
                      </div>

                      <div className="col-12 mb-4">
                        <label className="form-label h6">Bio</label>
                        <textarea
                          className="form-control"
                          name="bio"
                          value={fields.bio}
                          onChange={handleChange("bio")}
                          required="required"
                          rows="3"
                          placeholder="I'm a Digital Artist. Digital Art with over 3 years of experience. Experienced with all stages of the Art cycle for dynamic projects."
                        ></textarea>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <button
                          type="submit"
                          id="submit"
                          name="send"
                          className="btn btn-primary rounded-md"
                        >
                          Update Profile
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-5 col-12 order-1 order-md-2 mt-4 pt-2">
              <div className="card ms-lg-5">
                <div className="profile-pic">
                  <input
                    name="profile-image"
                    type="file"
                    className="d-none"
                    id="inputGroupFile01"
                    onChange={handleImageChange("bannerImage")}
                    accept="image/*"
                  />
                  <div className="position-relative d-inline-block w-100">
                    <img
                      src={images.bannerImage || "/images/blog/single.jpg"}
                      className="avatar avatar-medium img-thumbnail shadow-sm w-100"
                      id="banner-image"
                      alt=""
                    />
                    <label
                      className="icons position-absolute bottom-0 end-0"
                      htmlFor="inputGroupFile01"
                    >
                      <span className="btn btn-icon btn-sm btn-pills btn-primary">
                        {/* <i data-feather="camera" className="icons"></i> */}
                        <AiOutlineCamera />
                      </span>
                    </label>
                  </div>
                </div>

                <div className="mt-4">
                  <p className="text-muted mb-0">
                    We recommend an banner image of at least 1024 x 98.
                  </p>
                </div>
              </div>

              <div className="card ms-lg-5 mt-5">
                <div className="profile-pic">
                  <input
                    name="profile-image"
                    type="file"
                    className="d-none"
                    id="inputGroupFile02"
                    onChange={handleImageChange("profileImage")}
                    accept="image/*"
                  />
                  <div className="position-relative d-inline-block">
                    <img
                      src={images.profileImage || "/images/client/01.jpg"}
                      className="avatar avatar-medium img-thumbnail rounded-pill shadow-sm"
                      id="profile-image"
                      alt=""
                    />
                    <label
                      className="icons position-absolute bottom-0 end-0"
                      htmlFor="inputGroupFile02"
                    >
                      <span className="btn btn-icon btn-sm btn-pills btn-primary">
                        {/* <i data-feather="camera" className="icons"></i> */}
                        <AiOutlineCamera />
                      </span>
                    </label>
                  </div>
                </div>

                <div className="mt-4">
                  <p className="text-muted mb-0">
                    We recommend an image of at least 400X400.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

const mapStateToProps = ({ Login, Profile, File }) => ({
  user: Login.user,
  loading: Profile.loading || File.loading,
  error: Profile.error,
});

const mapDispatchToProps = (dispatch) => ({
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
  onEditUser: (data, callback) => dispatch(putProfile(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);

import { call, put, retry, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import {
  LOGIN_USER,
  GET_CURRENT_USER,
  LOGOUT_USER,
  GET_SETTINGS,
} from "./actionTypes";
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  getCurrentUserSuccess,
  getCurrentUserFail,
  getSettings as getSettingsAction,
  getSettingsSuccess,
  getSettingsFail,
} from "./actions";

import * as Metamask from "util/metamask";
import * as WalletConnect from "util/walletconnect";

import { postLogin, getCurrentUser, getSettings } from "helpers/backend_helper";

import { updateToken } from "helpers/api_helper";

function* loginUser({ payload: { user, navigate } }) {
  try {
    const response = yield call(postLogin, user);

    if (response.status === "failure") {
      return yield put(apiError(response.message));
    }

    localStorage.setItem("authUser", JSON.stringify(response));
    updateToken(response.data.Token);
    // yield put(loginSuccess({ data: { Token: user.account } }))
    yield put(loginSuccess(response));
    navigate("/");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* onGetCurrentUser() {
  try {
    const walletType = localStorage.getItem("wallet_type");
    let walletResponse;

    switch (walletType) {
      case "metamask":
        walletResponse = yield call(Metamask.getCurrentWalletConnected);
        break;

      case "walletconnect":
        walletResponse = yield call(WalletConnect.getCurrentWalletConnected);
        break;

      default:
        break;
    }

    if (walletResponse && !walletResponse.address) {
      switch (walletType) {
        case "walletconnect":
          walletResponse = yield call(WalletConnect.disconnect);
          break;

        default:
          break;
      }

      localStorage.removeItem("authUser");
      localStorage.removeItem("walletconnect");
      localStorage.removeItem("wallet_type");

      yield put(logoutUserSuccess());
    } else {
      const response = yield call(getCurrentUser);

      if (response.status === "failure") {
        return yield put(getCurrentUserFail(response.message));
      }

      yield put(getSettingsAction());
      yield put(getCurrentUserSuccess(response));
    }
  } catch (error) {
    console.log("error", error);
    yield put(getCurrentUserFail("Server Error"));
  }
}

function* logoutUser({ payload: { navigate, role } }) {
  try {
    const walletType = localStorage.getItem("wallet_type");

    switch (walletType) {
      case "walletconnect":
        yield call(WalletConnect.disconnect);
        break;

      default:
        break;
    }

    localStorage.removeItem("authUser");
    localStorage.removeItem("walletconnect");
    localStorage.removeItem("wallet_type");

    yield put(logoutUserSuccess());

    navigate("/");
  } catch (error) {
    console.log("error", error);
    yield put(apiError(error));
  }
}

function* onGetSettings({ payload }) {
  try {
    const response = yield call(getSettings);

    if (response.status === "failure") {
      return yield put(getSettingsFail());
    }

    yield put(getSettingsSuccess(response.data));
  } catch (error) {
    console.log("error", error);
    yield put(getSettingsFail(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(GET_CURRENT_USER, onGetCurrentUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(GET_SETTINGS, onGetSettings);
}

export default authSaga;

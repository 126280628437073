import {
  ITEM_API_FAIL,
  GET_ITEMS,
  GET_ITEMS_FAIL,
  GET_ITEMS_SUCCESS,
  POST_ITEM,
  POST_ITEM_FAIL,
  POST_ITEM_SUCCESS,
  POST_ITEM_SALE,
  POST_ITEM_SALE_FAIL,
  POST_ITEM_SALE_SUCCESS,
  GET_ITEM,
  GET_ITEM_BY_TOKEN,
  GET_ITEM_FAIL,
  GET_ITEM_SUCCESS,
  PUT_ITEM,
  PUT_ITEM_FAIL,
  PUT_ITEM_SUCCESS,
  DELETE_ITEM,
  DELETE_ITEM_FAIL,
  DELETE_ITEM_SUCCESS,
  GET_ACTIVITIES,
  GET_ACTIVITIES_FAIL,
  GET_ACTIVITIES_SUCCESS,
  POST_ITEM_BUY,
  POST_ITEM_BUY_FAIL,
  POST_ITEM_BUY_SUCCESS,
  POST_TOGGLE_ITEM_SALE,
  POST_TOGGLE_ITEM_SALE_FAIL,
  POST_TOGGLE_ITEM_SALE_SUCCESS,
  POST_BID,
  POST_BID_FAIL,
  POST_BID_SUCCESS,
  DELETE_BID,
  DELETE_BID_FAIL,
  DELETE_BID_SUCCESS,
  POST_BID_ACTION,
  POST_BID_ACTION_FAIL,
  POST_BID_ACTION_SUCCESS,
  GET_BIDS,
  GET_BIDS_FAIL,
  GET_BIDS_SUCCESS,
} from "./actionTypes"

export const apiFail = (error) => ({
  type: ITEM_API_FAIL,
  payload: error,
})

/* Get items */
export const getItems = (data) => ({
  type: GET_ITEMS,
  payload: data,
})

export const getItemsSuccess = (items) => ({
  type: GET_ITEMS_SUCCESS,
  payload: items,
})

export const getItemsFail = (error) => ({
  type: GET_ITEMS_FAIL,
  payload: error,
})
/* Get Items END */

/* Add Item */
export const postItem = (item, callback) => ({
  type: POST_ITEM,
  payload: { item, callback },
})

export const postItemFail = (error) => ({
  type: POST_ITEM_FAIL,
  payload: error,
})

export const postItemSuccess = () => ({
  type: POST_ITEM_SUCCESS,
})
/* Add Item END */

/* Add Item */
export const postItemSale = (data, callback) => ({
  type: POST_ITEM_SALE,
  payload: { data, callback },
})

export const postItemSaleFail = (error) => ({
  type: POST_ITEM_SALE_FAIL,
  payload: error,
})

export const postItemSaleSuccess = (data) => ({
  type: POST_ITEM_SALE_SUCCESS,
  payload: data,
})
/* Add Item END */

/* Get Item */
export const getItem = (id) => ({
  type: GET_ITEM,
  payload: id,
})

export const getItemByToken = (token) => ({
  type: GET_ITEM_BY_TOKEN,
  payload: token,
})

export const getItemFail = (error) => ({
  type: GET_ITEM_FAIL,
  payload: error,
})

export const getItemSuccess = (item) => ({
  type: GET_ITEM_SUCCESS,
  payload: item,
})
/* Get Item END */

/* Update Item */
export const putItem = (data, callback) => ({
  type: PUT_ITEM,
  payload: { data, callback },
})

export const putItemFail = (error) => ({
  type: PUT_ITEM_FAIL,
  payload: error,
})

export const putItemSuccess = () => ({
  type: PUT_ITEM_SUCCESS,
})
/* Update Item END */

/* Delete Item */
export const deleteItem = (data, callback) => ({
  type: DELETE_ITEM,
  payload: { data, callback },
})

export const deleteItemFail = (error) => ({
  type: DELETE_ITEM_FAIL,
  payload: error,
})

export const deleteItemSuccess = () => ({
  type: DELETE_ITEM_SUCCESS,
})
/* Delete Item END */

/* Get Activities */
export const getActivities = (data, callback) => ({
  type: GET_ACTIVITIES,
  payload: { data, callback },
})

export const getActivitiesFail = (error) => ({
  type: GET_ACTIVITIES_FAIL,
  payload: error,
})

export const getActivitiesSuccess = (data) => ({
  type: GET_ACTIVITIES_SUCCESS,
  payload: data,
})
/* Get Activities END */

/* Post Item Buy */
export const postItemBuy = (data, callback) => ({
  type: POST_ITEM_BUY,
  payload: { data, callback },
})

export const postItemBuyFail = (error) => ({
  type: POST_ITEM_BUY_FAIL,
  payload: error,
})

export const postItemBuySuccess = (data) => ({
  type: POST_ITEM_BUY_SUCCESS,
  payload: data,
})
/* Post Item Buy END */

/* Toggle Item Sale */
export const postToggleItemSale = (data, callback) => ({
  type: POST_TOGGLE_ITEM_SALE,
  payload: { data, callback },
})

export const postToggleItemSaleFail = (error) => ({
  type: POST_TOGGLE_ITEM_SALE_FAIL,
  payload: error,
})

export const postToggleItemSaleSuccess = (data) => ({
  type: POST_TOGGLE_ITEM_SALE_SUCCESS,
  payload: data,
})
/* Toggle Item Sale */

/* Post Bid */
export const postBid = (data, callback) => ({
  type: POST_BID,
  payload: { data, callback },
})

export const postBidFail = (error) => ({
  type: POST_BID_FAIL,
  payload: error,
})

export const postBidSuccess = (data) => ({
  type: POST_BID_SUCCESS,
  payload: data,
})
/* Post Bid END */

/* Delete Bid */
export const deleteBid = (data, callback) => ({
  type: DELETE_BID,
  payload: { data, callback },
})

export const deleteBidFail = (error) => ({
  type: DELETE_BID_FAIL,
  payload: error,
})

export const deleteBidSuccess = (data) => ({
  type: DELETE_BID_SUCCESS,
  payload: data,
})
/* Delete Bid END */

/* Post Bid Action */
export const postBidAction = (data, callback) => ({
  type: POST_BID_ACTION,
  payload: { data, callback },
})

export const postBidActionFail = (error) => ({
  type: POST_BID_ACTION_FAIL,
  payload: error,
})

export const postBidActionSuccess = (data) => ({
  type: POST_BID_ACTION_SUCCESS,
  payload: data,
})
/* Post Bid Action END */

/* Get Bids */
export const getBids = (data, callback) => ({
  type: GET_BIDS,
  payload: { data, callback },
})

export const getBidsFail = (error) => ({
  type: GET_BIDS_FAIL,
  payload: error,
})

export const getBidsSuccess = (data) => ({
  type: GET_BIDS_SUCCESS,
  payload: data,
})
/* Get Bids END */

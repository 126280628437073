import {
  GET_HOMEPAGE,
  GET_HOMEPAGE_FAIL,
  GET_HOMEPAGE_SUCCESS,
  GET_MOSTLY_VIEW,
  GET_MOSTLY_VIEW_FAIL,
  GET_MOSTLY_VIEW_SUCCESS,
  GET_LATEST,
  GET_LATEST_SUCCESS,
  GET_LATEST_FAIL,
  GET_TOP_COLLECTIONS,
  GET_TOP_COLLECTIONS_SUCCESS,
  GET_TOP_COLLECTIONS_FAIL,
  GET_EXPLORE,
  GET_EXPLORE_SUCCESS,
  GET_EXPLORE_FAIL,
} from "./actionTypes"

/* Get Homepage */
export const getHomepage = (data, callback) => ({
  type: GET_HOMEPAGE,
  payload: { data, callback },
})

export const getHomepageFail = (error) => ({
  type: GET_HOMEPAGE_FAIL,
  payload: error,
})

export const getHomepageSuccess = (data) => ({
  type: GET_HOMEPAGE_SUCCESS,
  payload: data,
})
/* Get Homepage END */

/* Get Mostly Viewed */
export const getMostlyView = (data, callback) => ({
  type: GET_MOSTLY_VIEW,
  payload: { data, callback },
})

export const getMostlyViewFail = (error) => ({
  type: GET_MOSTLY_VIEW_FAIL,
  payload: error,
})

export const getMostlyViewSuccess = (data) => ({
  type: GET_MOSTLY_VIEW_SUCCESS,
  payload: data,
})
/* Get Mostly Viewed END */

/* Get Latest NFT */
export const getLatest = (data, callback) => ({
  type: GET_LATEST,
  payload: { data, callback },
})

export const getLatestFail = (error) => ({
  type: GET_LATEST_FAIL,
  payload: error,
})

export const getLatestSuccess = (data) => ({
  type: GET_LATEST_SUCCESS,
  payload: data,
})
/* Get Latest NFT END */

/* Get Top Collections */
export const getTopCollections = (data, callback) => ({
  type: GET_TOP_COLLECTIONS,
  payload: { data, callback },
})

export const getTopCollectionsFail = (error) => ({
  type: GET_TOP_COLLECTIONS_FAIL,
  payload: error,
})

export const getTopCollectionsSuccess = (data) => ({
  type: GET_TOP_COLLECTIONS_SUCCESS,
  payload: data,
})
/* Get Top Collections END */

/* Get Explore */
export const getExplore = (data, callback) => ({
  type: GET_EXPLORE,
  payload: { data, callback },
})

export const getExploreFail = (error) => ({
  type: GET_EXPLORE_FAIL,
  payload: error,
})

export const getExploreSuccess = (data) => ({
  type: GET_EXPLORE_SUCCESS,
  payload: data,
})
/* Get Explore END */

// Verify Collection By Name
export const GET_COLLECTION_NAME_VERIFY = "GET_COLLECTION_NAME_VERIFY"
export const GET_COLLECTION_NAME_VERIFY_FAIL = "GET_COLLECTION_NAME_VERIFY_FAIL"
export const GET_COLLECTION_NAME_VERIFY_SUCCESS =
  "GET_COLLECTION_NAME_VERIFY_SUCCESS"

// Get Collection ByteCode
export const GET_COLLECTION_BYTECODE = "GET_COLLECTION_BYTECODE"
export const GET_COLLECTION_BYTECODE_FAIL = "GET_COLLECTION_BYTECODE_FAIL"
export const GET_COLLECTION_BYTECODE_SUCCESS = "GET_COLLECTION_BYTECODE_SUCCESS"

// COLLECTIONs
export const GET_COLLECTIONS = "GET_COLLECTIONS"
export const GET_COLLECTIONS_FAIL = "GET_COLLECTIONS_FAIL"
export const GET_COLLECTIONS_SUCCESS = "GET_COLLECTIONS_SUCCESS"

// Add COLLECTION
export const POST_COLLECTION = "POST_COLLECTION"
export const POST_COLLECTION_FAIL = "POST_COLLECTION_FAIL"
export const POST_COLLECTION_SUCCESS = "POST_COLLECTION_SUCCESS"

// Get COLLECTION
export const GET_COLLECTION_BY_ADDRESS = "GET_COLLECTION_BY_ADDRESS"
export const GET_COLLECTION = "GET_COLLECTION"
export const GET_COLLECTION_FAIL = "GET_COLLECTION_FAIL"
export const GET_COLLECTION_SUCCESS = "GET_COLLECTION_SUCCESS"

// update COLLECTION
export const PUT_COLLECTION = "PUT_COLLECTION"
export const PUT_COLLECTION_FAIL = "PUT_COLLECTION_FAIL"
export const PUT_COLLECTION_SUCCESS = "PUT_COLLECTION_SUCCESS"

// Delete COLLECTION
export const DELETE_COLLECTION = "DELETE_COLLECTION"
export const DELETE_COLLECTION_FAIL = "DELETE_COLLECTION_FAIL"
export const DELETE_COLLECTION_SUCCESS = "DELETE_COLLECTION_SUCCESS"

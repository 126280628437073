import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { connect } from "react-redux"
import queryString from "query-string"

// Components
import Tabs from "./Tabs"
import Items from "./Items"
import Collections from "./Collections"
import Activities from "./Activities"

const TABS = [
  {
    label: "Created",
    key: "created",
  },
  {
    label: "Collected",
    key: "collected",
  },
  {
    label: "Collections",
    key: "collections",
  },
  {
    label: "Activities",
    key: "activities",
  },
]

const IS_VALID_TAB = (tab) => {
  const _INDEX = TABS.findIndex((item) => item.key === tab)

  return _INDEX !== -1
}

const CreatorCollectionList = (props) => {
  const location = useLocation()
  const { tab } = queryString.parse(location.search)

  const [currenttab, setcurrenttab] = useState("created")

  useEffect(() => {
    const _TAB = tab?.toLowerCase() || "created"

    if (IS_VALID_TAB(_TAB)) {
      setcurrenttab(_TAB)
    } else {
      setcurrenttab("created")
    }
  }, [tab])

  return (
    <section className="bg-creator-profile">
      <div className="container pt-0">
        <div className="row">
          <div className="col-12">
            <Tabs TABS={TABS} currenttab={currenttab} />

            <div className="tab-content mt-4 pt-2">
              <div className="tab-pane fade show active">
                {["created", "collected"].includes(currenttab) && (
                  <Items userId={props.userId} currentTab={currenttab} />
                )}
                {currenttab === "collections" && (
                  <Collections userId={props.userId} />
                )}
                {currenttab === "activities" && (
                  <Activities userId={props.userId} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = ({ Profile }) => ({
  userId: Profile.userProfile?._id,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreatorCollectionList)

import * as TYPES from "./actionTypes"

const INIT_STATE = {
  loading: false,
  error: "",
  faqs: [],
  totalFaqs: 0,
  faq: {},
}

const Reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* List */
    case TYPES.GET_FAQS_SUCCESS:
      return {
        ...state,
        loading: false,
        faqs: action.payload.data,
        totalFaqs: action.payload.totalCount,
      }
    /* List END */

    /* Default Start */
    case TYPES.GET_FAQS:
      return {
        ...state,
        loading: true,
        error: "",
      }
    /* Default Start */

    /* Default Failure */
    case TYPES.GET_FAQS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    /* Default Failure END */

    default:
      return state
  }
}

export default Reducer

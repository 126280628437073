import * as TYPES from "./actionTypes"

/* List */
export const getFaqs = (data, callback) => ({
  type: TYPES.GET_FAQS,
  payload: { data, callback },
})

export const getFaqsSuccess = (data) => ({
  type: TYPES.GET_FAQS_SUCCESS,
  payload: data,
})

export const getFaqsFail = (error) => ({
  type: TYPES.GET_FAQS_FAIL,
  payload: error,
})
/* List END */

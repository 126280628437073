import React from "react"

import Footer from "../components/footer/Footer"
import Header from "../components/header/Header"
import CreateCollectionComp from "components/CreateCollection/CreateCollection"

const CreateCollection = (props) => {
  return (
    <div>
      <Header />
      <CreateCollectionComp />
      <Footer />
    </div>
  )
}

export default CreateCollection

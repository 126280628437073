import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Spinner } from "reactstrap"

// Actions
import { getTopCollections } from "store/actions"

//
import Collection from "components/UserProfile/Collections/Collection"

const NftExplore = (props) => {
  const [topCollections, settopCollections] = useState([])
  const [filter, setfilter] = useState({
    limit: 10,
    orderBy: "",
    order: "",
    page: 1,
  })

  useEffect(() => {
    props.onGetTopCollections(filter)
  }, [filter])

  useEffect(() => {
    if (props.topCollections) {
      if (filter.isMore) {
        settopCollections((prevState) => [
          ...prevState,
          ...props.topCollections,
        ])
      } else {
        settopCollections(props.topCollections)
      }
    }
  }, [props.topCollections])

  const showMoreItems = (evt) => {
    evt.preventDefault()

    setfilter((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
      isMore: true,
    }))
  }

  return (
    <div className="">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col">
            <div className="section-title text-center mb-4 pb-2">
              <h4 className="title mb-3 PH-font">Explore Top Collections</h4>
              <p className="text-muted para-desc mb-0 mx-auto SH-font">
                We are a huge marketplace dedicated to connecting great artists
                of all artspray with their fans and unique token collectors!
              </p>
            </div>
          </div>
        </div>

        <div className="row items spinner-content">
          {props.loading && (
            <div className="spinner">
              <Spinner color="primary" />
            </div>
          )}

          {topCollections.map((item, idx) => (
            <div key={`exo_${idx}`} className="col-12 item">
              <Collection needSeller={true} item={item} />
            </div>
          ))}
        </div>

        {topCollections.length < props.totalTopCollections && (
          <div className="row">
            <div className="col-12 text-center">
              <a
                className="btn btn-bordered-white mt-5"
                href="#"
                onClick={showMoreItems}
              >
                Load More
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = ({ home }) => ({
  loading: home.loading,
  totalTopCollections: home.totalTopCollections,
  topCollections: home.topCollections,
})

const mapDispatchToProps = (dispatch) => ({
  onGetTopCollections: (data, callback) =>
    dispatch(getTopCollections(data, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NftExplore)

// Get All
export const GET_FAQS = "GET_FAQS"
export const GET_FAQS_SUCCESS = "GET_FAQS_SUCCESS"
export const GET_FAQS_FAIL = "GET_FAQS_FAIL"

// Post Contact Us
export const POST_CONTACT_US = "POST_CONTACT_US"
export const POST_CONTACT_US_SUCCESS = "POST_CONTACT_US_SUCCESS"
export const POST_CONTACT_US_FAIL = "POST_CONTACT_US_FAIL"

// Content Pages
export const GET_CONTENT_PAGE = "GET_CONTENT_PAGE"
export const GET_CONTENT_PAGE_SUCCESS = "GET_CONTENT_PAGE_SUCCESS"
export const GET_CONTENT_PAGE_FAIL = "GET_CONTENT_PAGE_FAIL"

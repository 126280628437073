import {
  GET_FAQS,
  GET_FAQS_SUCCESS,
  GET_FAQS_FAIL,
  POST_CONTACT_US,
  POST_CONTACT_US_FAIL,
  POST_CONTACT_US_SUCCESS,
  GET_CONTENT_PAGE,
  GET_CONTENT_PAGE_FAIL,
  GET_CONTENT_PAGE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  loading: false,
  faqs: [],
  content: "",
}

const ContentPages = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Faqs */
    case GET_FAQS:
      return {
        ...state,
        loading: true,
      }

    case GET_FAQS_SUCCESS:
      return {
        ...state,
        loading: false,
        faqs: action.payload,
      }

    case GET_FAQS_FAIL:
      return {
        ...state,
        loading: false,
      }
    /* Get Faqs END */

    /* Post Contact Us */
    case POST_CONTACT_US:
      return {
        ...state,
        loading: true,
      }

    case POST_CONTACT_US_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case POST_CONTACT_US_FAIL:
      return {
        ...state,
        loading: false,
      }
    /* Post Contact Us END */

    /* Get Content Page */
    case GET_CONTENT_PAGE:
      return {
        ...state,
        loading: true,
        content: "",
      }

    case GET_CONTENT_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        content: action.payload,
      }

    case GET_CONTENT_PAGE_FAIL:
      return {
        ...state,
        loading: false,
      }
    /* Get Content Page END */

    default:
      return state
  }
}

export default ContentPages

import React, { Component } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"

//
import CollectionDetails from "components/CollectionProfile/CollectionDetails"
import Explore from "components/CollectionProfile/Explore"

import Footer from "components/footer/Footer"
import Header from "components/header/Header"

const UserProfile = (props) => {
  let { address } = useParams()

  return (
    <React.Fragment>
      <Header />
      <CollectionDetails address={address} />
      <Explore collectionId={props.collection?._id} />
      <Footer />
    </React.Fragment>
  )
}

const mapStateToProps = ({ Profile, collections }) => ({
  userProfile: Profile.userProfile,
  collection: collections.collection,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)

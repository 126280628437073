import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { logoutUser } from "store/actions";

// Hooks
import useMetaMask from "hooks/wallet";

const Logout = (props) => {
  const navigate = useNavigate();
  const { disconnect } = useMetaMask();

  useEffect(() => {
    disconnect();
    props.logoutUser(navigate, props?.role);
  }, []);

  return <></>;
};

Logout.propTypes = {
  logoutUser: PropTypes.func,
};

const mapStateToProps = ({ Login }) => ({ role: Login?.user?.role });

export default connect(mapStateToProps, { logoutUser })(Logout);

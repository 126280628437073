import { call, put, takeEvery } from "redux-saga/effects"

import { GET_FAQS, POST_CONTACT_US, GET_CONTENT_PAGE } from "./actionTypes"
import {
  getFaqsFail,
  getFaqsSuccess,
  postContactUsFail,
  postContactUsSuccess,
  getContentPageFail,
  getContentPageSuccess,
} from "./actions"

import { getFaqs, postContactUs, getContentPage } from "helpers/backend_helper"

function* onGetFaq({ payload: { data, callback } }) {
  try {
    const response = yield call(getFaqs, data)

    if (response.status === "failure") {
      return yield put(getFaqsFail(response.message))
    }

    callback && callback(response)
    yield put(getFaqsSuccess(response.data))
  } catch (error) {
    console.log("error", error)
    yield put(getFaqsFail(""))
  }
}

function* onPostContactUs({ payload: { data, callback } }) {
  try {
    const response = yield call(postContactUs, data)

    if (response.status === "failure") {
      return yield put(postContactUsFail(response.message))
    }

    callback && callback(response)
    yield put(postContactUsSuccess(response.data))
  } catch (error) {
    console.log("error", error)
    yield put(postContactUsFail(""))
  }
}

function* onGetContentPage({ payload }) {
  try {
    const response = yield call(getContentPage, payload)

    if (response.status === "failure") {
      return yield put(getContentPageFail(response.message))
    }

    yield put(getContentPageSuccess(response.data?.content))
  } catch (error) {
    console.log("error", error)
    yield put(getContentPageFail(""))
  }
}

function* contentPagesSaga() {
  yield takeEvery(GET_FAQS, onGetFaq)
  yield takeEvery(POST_CONTACT_US, onPostContactUs)
  yield takeEvery(GET_CONTENT_PAGE, onGetContentPage)
}

export default contentPagesSaga

import React from "react"
import { Modal, ModalBody } from "reactstrap"

const BidModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} centered>
      <div className="modal-header pb-2">
        <h5 className="m-0 title-heading">Bid Price</h5>
      </div>

      <hr className="my-1 border-top border-white" />

      <ModalBody>
        <form onSubmit={props.onConfirm} className="item-form">
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="form-label fw-bold">
                  Bid Price <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="email"
                  value={props.price}
                  onChange={props.onPriceChange}
                  placeholder="Enter Bid Price"
                  min={0}
                  step="0.00000001"
                  required
                />
              </div>
            </div>

            <div className="col-12">
              <button
                className="btn btn-l btn-pills btn-primary w-100"
                type="submit"
              >
                Place a Bid
              </button>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default BidModal

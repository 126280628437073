import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaDiscord } from "react-icons/fa";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  const { footerSetting, socialMediaSetting } = useSelector(
    (state) => state.Login?.settings || {}
  );
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <section className="py-0">
      <footer className="bg-footer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="footer-py-60 footer-border">
                <div className="row">
                  <div className="col-lg-3 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                    <div className="foot-subscribe mb-3">
                      <a href="#" className="logo-footer">
                        <img
                          src="/images/artspray-white-logo.png"
                          className="img-fluid"
                          alt=""
                        />
                      </a>
                      <p className="para-desc mb-0 mt-4">
                        {footerSetting?.description}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0 offset-md-2">
                    <h5 className="footer-head position-relative pb-1">
                      Navigation
                    </h5>
                    <ul className="list-unstyled footer-list mt-4">
                      <li>
                        <Link to="/explore" className="text-foot">
                          {" "}
                          Explore
                        </Link>
                      </li>
                      <li>
                        <Link to="/activities" className="text-foot">
                          {" "}
                          Activities
                        </Link>
                      </li>
                    </ul>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000000"
                      viewBox="0 0 50 50"
                      width="50px"
                      height="50px"
                    >
                      {" "}
                      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
                    </svg>
                  </div>

                  <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <h5 className="footer-head position-relative pb-1">
                      HELP CENTER
                    </h5>
                    <ul className="list-unstyled footer-list mt-4">
                      <li>
                        <Link to="/faq" className="text-foot">
                          FAQ
                        </Link>
                      </li>

                      <li>
                        <Link to="/how-to" className="text-foot">
                          How To
                        </Link>
                      </li>

                      <li>
                        <Link to="/contact" className="text-foot">
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <ul className="list-unstyled social-icon foot-social-icon">
                      <li className="list-inline-item lh-1 px-1">
                        <a
                          href={socialMediaSetting?.instagram}
                          target="_blank"
                          className="rounded"
                        >
                          <i className="uil uil-instagram"></i>
                        </a>
                      </li>

                      <li className="list-inline-item lh-1 px-1">
                        <a
                          href={socialMediaSetting?.discord}
                          target="_blank"
                          className="rounded"
                        >
                          <i className="uil uil-discord"></i>
                        </a>
                      </li>

                      <li className="list-inline-item lh-1 px-1">
                        <a
                          href={socialMediaSetting?.linkedin}
                          target="_blank"
                          className="rounded"
                        >
                          <i className="uil uil-linkedin"></i>
                        </a>
                      </li>
                      <li className="list-inline-item lh-1 px-1">
                        <a
                          href={socialMediaSetting?.twitter}
                          target="_blank"
                          className="rounded"
                        >
                          <i className="uil uil-twitter"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <footer className="footer text-center">
        <Container fluid={true}>
          <Row>
            <Col md={12} className="py-3">
              <a
                className="text-dark"
                href="https://www.suffescom.com/"
                target="_blank"
              >
                A Product by Suffescom Solutions Pvt. Ltd.
              </a>
            </Col>
          </Row>
        </Container>
      </footer>
    </section>
  );
};

export default Footer;

import React, { Component } from "react"

import Collections from "../components/Collections/CollectionsTwo"

import Footer from "../components/footer/Footer"
import Header from "../components/header/Header"

class TopCollections extends Component {
  render() {
    return (
      <div className="main">
        <Header />
        <Collections />
        <Footer />
      </div>
    )
  }
}

export default TopCollections

import React, { useState, useEffect, useMemo } from "react";
import { convertHexToString } from "./web3Intraction";
import * as Metamask from "./metamask";
import * as WalletConnect from "./walletconnect";

export const WalletContext = React.createContext(null);

export const WalletProvider = ({ children }) => {
  const [walletType, setwalletType] = useState(
    localStorage.getItem("wallet_type")
  );
  const [isActive, setIsActive] = useState(false);
  const [account, setaccount] = useState("");
  const [chainId, setchainId] = useState();

  // Init Loading
  useEffect(() => {
    switch (walletType) {
      case "metamask":
        if (window.ethereum) {
          addWalletListener();
          addNetworkListener();
        } else {
          // You must install Metamask, a virtual Ethereum wallet, in your browser.
          setIsActive(false);
        }
        break;

      case "walletconnect":
        addSessionListener();
        break;

      default:
        setIsActive(false);
        break;
    }
  }, [walletType]);

  // Connect to Wallet
  const connect = async (_walletType) => {
    setwalletType(_walletType);
    localStorage.setItem("wallet_type", _walletType);

    switch (_walletType) {
      case "metamask":
        const _metamaskResponse = await Metamask.connectWallet();

        setaccount(_metamaskResponse.address);
        setIsActive(true);
        break;

      case "walletconnect":
        const _connectResponse = await WalletConnect.connectWallet();
        setaccount(_connectResponse.address);
        setIsActive(true);
        setchainId(_connectResponse.chainId);
        break;

      default:
        setaccount("");
        setIsActive(false);
        break;
    }
  };

  // Disconnect from Metamask wallet
  const disconnect = async () => {
    setaccount("");
    setIsActive(false);
  };

  function addSessionListener() {
    setchainId(WalletConnect.getCurrentChain());
    WalletConnect.onSessionUpdate(({ accounts, chainId }) => {
      if (accounts.length > 0) {
        setIsActive(true);
        setaccount(accounts[0]);
      } else {
        setIsActive(false);
        setaccount("");
      }

      setchainId(chainId);
    });
  }

  function addWalletListener() {
    Metamask.onAccountChange((accounts) => {
      if (accounts.length > 0) {
        setIsActive(true);
        setaccount(accounts[0]);
      } else {
        setIsActive(false);
        setaccount("");
      }
    });
  }

  async function addNetworkListener() {
    setchainId(convertHexToString(await Metamask.getCurrentChain()));

    Metamask.onChainChange((res) => {
      setchainId(convertHexToString(res));
    });
  }

  const values = useMemo(
    () => ({
      isActive,
      account,
      connect,
      disconnect,
      chainId,
      walletType,
    }),
    [isActive, account, chainId, walletType]
  );

  return (
    <WalletContext.Provider value={values}>{children}</WalletContext.Provider>
  );
};

export const ITEM_API_FAIL = "ITEM_API_FAIL"

// ITEMs
export const GET_ITEMS = "GET_ITEMS"
export const GET_ITEMS_FAIL = "GET_ITEMS_FAIL"
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS"

// Add ITEM
export const POST_ITEM = "POST_ITEM"
export const POST_ITEM_FAIL = "POST_ITEM_FAIL"
export const POST_ITEM_SUCCESS = "POST_ITEM_SUCCESS"

// Post ITEM on Sale
export const POST_ITEM_SALE = "POST_ITEM_SALE"
export const POST_ITEM_SALE_FAIL = "POST_ITEM_SALE_FAIL"
export const POST_ITEM_SALE_SUCCESS = "POST_ITEM_SALE_SUCCESS"

// Get ITEM
export const GET_ITEM = "GET_ITEM"
export const GET_ITEM_BY_TOKEN = "GET_ITEM_BY_TOKEN"
export const GET_ITEM_FAIL = "GET_ITEM_FAIL"
export const GET_ITEM_SUCCESS = "GET_ITEM_SUCCESS"

// update ITEM
export const PUT_ITEM = "PUT_ITEM"
export const PUT_ITEM_FAIL = "PUT_ITEM_FAIL"
export const PUT_ITEM_SUCCESS = "PUT_ITEM_SUCCESS"

// Delete ITEM
export const DELETE_ITEM = "DELETE_ITEM"
export const DELETE_ITEM_FAIL = "DELETE_ITEM_FAIL"
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS"

// Get Activities
export const GET_ACTIVITIES = "GET_ACTIVITIES"
export const GET_ACTIVITIES_FAIL = "GET_ACTIVITIES_FAIL"
export const GET_ACTIVITIES_SUCCESS = "GET_ACTIVITIES_SUCCESS"

// Buy ITEM
export const POST_ITEM_BUY = "POST_ITEM_BUY"
export const POST_ITEM_BUY_FAIL = "POST_ITEM_BUY_FAIL"
export const POST_ITEM_BUY_SUCCESS = "POST_ITEM_BUY_SUCCESS"

// Toggle Sale
export const POST_TOGGLE_ITEM_SALE = "POST_TOGGLE_ITEM_SALE"
export const POST_TOGGLE_ITEM_SALE_FAIL = "POST_TOGGLE_ITEM_SALE_FAIL"
export const POST_TOGGLE_ITEM_SALE_SUCCESS = "POST_TOGGLE_ITEM_SALE_SUCCESS"

// Place Bid
export const POST_BID = "POST_BID"
export const POST_BID_FAIL = "POST_BID_FAIL"
export const POST_BID_SUCCESS = "POST_BID_SUCCESS"

// Delete Bid
export const DELETE_BID = "DELETE_BID"
export const DELETE_BID_FAIL = "DELETE_BID_FAIL"
export const DELETE_BID_SUCCESS = "DELETE_BID_SUCCESS"

// Post Bid Action
export const POST_BID_ACTION = "POST_BID_ACTION"
export const POST_BID_ACTION_FAIL = "POST_BID_ACTION_FAIL"
export const POST_BID_ACTION_SUCCESS = "POST_BID_ACTION_SUCCESS"

// Get Bids
export const GET_BIDS = "GET_BIDS"
export const GET_BIDS_FAIL = "GET_BIDS_FAIL"
export const GET_BIDS_SUCCESS = "GET_BIDS_SUCCESS"

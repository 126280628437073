import React from "react"

// Components
import Header from "components/header/Header"
import AuthorProfile from "components/AuthorProfile/AuthorProfile"
import Explore from "components/UserProfile/Explore"
import Footer from "components/footer/Footer"

const Profile = () => {
  return (
    <React.Fragment>
      <Header />
      <AuthorProfile />
      <Explore />
      <Footer />
    </React.Fragment>
  )
}

export default Profile

import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

// Helpers
import { ACTIVITY_TYPE, BLOCKCHAIN_CURRENCY_CODE } from "helpers/constants";

const Activity = (props) => {
  return (
    <React.Fragment>
      <div className="card activity activity-primary rounded-md shadow p-3">
        <div className="d-flex align-items-center">
          <div className="position-relative">
            <Link to={`/token/${props.item.item?._id}`}>
              {props.item.item?.media?.link &&
              props.item.item.media.mimeType?.includes("video") ? (
                <video
                  className="avatar avatar-md-md rounded-md shadow-md"
                  src={props.item.item?.media?.link || "/images/items/1.jpg"}
                  alt=""
                  controls
                />
              ) : (
                <img
                  className="avatar avatar-md-md rounded-md shadow-md"
                  src={props.item.item?.media?.link || "/images/items/1.jpg"}
                  alt=""
                />
              )}
            </Link>
          </div>

          <span className="content ms-3">
            {!props.noTitle && (
              <Link
                to={`/token/${props.item.item?._id}`}
                className="text-dark title mb-0 h6 d-block"
              >
                {props.item.item?.name}
              </Link>
            )}

            <small className="text-muted d-block mt-1">
              <span className="text-capitalize">
                {ACTIVITY_TYPE[props.item.history_type]}
              </span>{" "}
              listed for{" "}
              <strong>
                {Number(props.item.price).toFixed(8)}{" "}
                {BLOCKCHAIN_CURRENCY_CODE[props.item.item?.blockchain]}
              </strong>
              <br />
              {["transfer", "admin_comission"].includes(
                props.item.history_type
              ) &&
                (props.item.from ? (
                  <>
                    from{" "}
                    <Link
                      to={`/user/${props.item.from.walletAddress}`}
                      className="link fw-bold"
                    >
                      {props.item.from.name || "-"}
                    </Link>
                    {" to "}
                    <Link
                      to={`/user/${props.item.to.walletAddress}`}
                      className="link fw-bold"
                    >
                      {props.item.to.name}
                    </Link>
                  </>
                ) : (
                  <>
                    by{" "}
                    <Link
                      to={`/user/${props.item.to.walletAddress}`}
                      className="link fw-bold"
                    >
                      {props.item.to.name}
                    </Link>
                  </>
                ))}
            </small>

            <small className="text-muted d-block mt-1">
              {moment(props.item.created_date).toNow()}
            </small>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Activity);

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import toastr from "toastr";
import { Alert, Spinner } from "reactstrap";

// Actions
import {
  getCollection,
  getItemByToken,
  postItemBuy,
  postBid,
} from "store/actions";

// Hooks
import useWallet from "hooks/wallet";

// Util
import {
  BLOCKCHAIN_CURRENCY_CODE,
  BLOCKCHAIN_CURRENCY_KEY,
  BLOCKCHAIN_ENUM,
} from "helpers/constants";
import Web3Intraction from "util/web3Intraction";
import { getPrices } from "helpers/backend_helper";

// Components
import ActivityItem from "components/UserProfile/Activities/Activity";
import Offer from "components/UserProfile/Activities/Offer";
import BidModal from "./BidModal";

const Itemdescription = (props) => {
  const { onGetItemByToken } = props;
  const params = useParams();
  const { walletType } = useWallet();

  const [tab, settab] = useState("details");
  const [isCurrentUser, setisCurrentUser] = useState(false);
  const [internalLoading, setinternalLoading] = useState(false);
  const [price, setprice] = useState(null);
  const [isBid, setisBid] = useState(false);
  const [prices, setprices] = useState({});

  useEffect(() => {
    getPrices().then((data) => setprices(data));
  });

  useEffect(() => {
    if (params.token_uri_id) {
      onGetItemByToken(params.token_uri_id);
    }
  }, [params.token_uri_id]);

  useEffect(() => {
    if (props.user?._id === props.item?.current_owner?._id) {
      setisCurrentUser(true);
    } else {
      setisCurrentUser(false);
    }
  }, [props.user, props.item]);

  const onBidPayment = (item, afterPayment) => {
    if (props.settings && props.item && JSON.stringify(props.item) !== "{}") {
      if (!window.ethereum) {
        toastr.error(
          "Connect your Metamask wallet to update the message on the blockchain."
        );
        return;
      }

      setinternalLoading(true);

      const web3intraction = new Web3Intraction(
        item.blockchain,
        walletType,
        props.settings
      );

      if (!web3intraction.isNetworkServiceWorking()) {
        setinternalLoading(false);
        return toastr.error("Service Not Available!");
      }

      const getCollectionData = async (response) => {
        try {
          await web3intraction.verifyApproved(
            props.user?.walletAddress,
            response
          );
          const receipt = await web3intraction.sendTransaction(
            props.user?.walletAddress,
            item
          );
          console.log("Got receipt", receipt);

          afterPayment && afterPayment(receipt);
          setinternalLoading(false);
        } catch (error) {
          console.log("error", error);
          setinternalLoading(false);
        }
      };

      web3intraction
        .switchChain()
        .then(() =>
          props.onGetCollection(item.collection_id?._id, getCollectionData)
        )
        .catch((error) => {
          toastr.error(error?.message);
          setinternalLoading(false);
        });
    }
  };

  const onBid = (evt) => {
    try {
      toggleBid(evt);
      const data = {
        type: props.item.blockchain || "ethereum",
        itemId: props.item._id,
        price,
      };

      props.onPostBid(data, () => {
        onGetItemByToken(params.token_uri_id);
      });
      setinternalLoading(false);
    } catch (error) {
      setinternalLoading(false);
      toastr.error(error.message);
    }
  };

  const onBuy = (evt) => {
    evt.preventDefault();

    if (props.settings && props.item && JSON.stringify(props.item) !== "{}") {
      if (!window.ethereum) {
        toastr.error(
          "Connect your Metamask wallet to update the message on the blockchain."
        );
        return;
      }

      setinternalLoading(true);

      const web3intraction = new Web3Intraction(
        props.item.blockchain,
        walletType,
        props.settings
      );

      if (!web3intraction.isNetworkServiceWorking()) {
        return toastr.error("Service Not Available!");
      }

      const getCollectionData = async (response) => {
        try {
          await web3intraction.verifyApproved(
            props.user?.walletAddress,
            response
          );
          const receipt = await web3intraction.sendTransaction(
            props.user?.walletAddress,
            props.item
          );
          console.log("Got receipt", receipt);

          const data = {
            type: props.item.blockchain || "ethereum",
            itemId: props.item._id,
            transactionHash: receipt.transactionHash,
          };

          props.onPostItemBuy(data, () => {
            onGetItemByToken(params.token_uri_id);
          });
          setinternalLoading(false);
        } catch (error) {
          console.log("error", error);
          setinternalLoading(false);
        }
      };

      web3intraction
        .switchChain()
        .then(() =>
          props.onGetCollection(
            props.item.collection_id?._id,
            getCollectionData
          )
        )
        .catch((error) => {
          toastr.error(error?.message);
          setinternalLoading(false);
        });
    }
  };

  const toggleBid = (evt) => {
    evt?.preventDefault();
    setisBid((prevState) => !prevState);
  };

  return (
    <React.Fragment>
      <BidModal
        isOpen={isBid}
        toggle={toggleBid}
        price={price}
        onPriceChange={(evt) => setprice(evt.target.value)}
        onConfirm={onBid}
      />

      <div className="d-table w-100">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="sticky-bar">
                <img
                  src={props.item.media?.link}
                  className="img-fluid rounded-md shadow"
                  alt=""
                />
              </div>
            </div>

            <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0 spinner-content">
              {props.error && typeof props.error === "string" ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}

              {(props.loading || internalLoading) && (
                <div className="spinner">
                  <Spinner color="primary" />
                </div>
              )}

              <div className="ms-lg-5">
                <div className="title-heading">
                  <h4 className="h3 fw-bold mb-0">
                    <span className="text-gradient-primary">
                      {props.item.name}
                    </span>
                  </h4>
                </div>

                <div className="row">
                  <div className="col-md-6 mt-4 pt-2">
                    <h6>Current Price</h6>
                    <h4 className="mb-0">
                      {props.item.price}{" "}
                      {BLOCKCHAIN_CURRENCY_CODE[props.item.blockchain]}
                    </h4>
                    {prices &&
                      prices[
                        BLOCKCHAIN_CURRENCY_KEY[props.item.blockchain]
                      ] && (
                        <small className="mb-0 text-muted">
                          $
                          {Number(
                            prices[
                              BLOCKCHAIN_CURRENCY_KEY[props.item.blockchain]
                            ].usd * props.item.price
                          )}
                        </small>
                      )}
                  </div>

                  {!isCurrentUser && (
                    <div className="col-12 mt-4 pt-2">
                      {props.item.status === "active" && (
                        <Link
                          className="btn-readMore "
                          to="buy"
                          onClick={onBuy}
                        >
                          <i className="mdi mdi-cart fs-5 me-2"></i> Buy Now
                        </Link>
                      )}

                      <Link
                        className="btn-readMore "
                        to="buy"
                        onClick={toggleBid}
                      >
                        <i className="mdi mdi-gavel fs-5 me-2"></i> Place a Bid
                      </Link>
                    </div>
                  )}
                </div>

                <div className="row mt-4 pt-2">
                  <div className="col-12">
                    <ul className="nav nav-tabs border-bottom">
                      <li className="nav-item" role="presentation">
                        <button
                          className={classnames("nav-link", {
                            active: tab === "details",
                          })}
                          type="button"
                          onClick={() => settab("details")}
                        >
                          Details
                        </button>
                      </li>

                      <li className="nav-item" role="presentation">
                        <button
                          className={classnames("nav-link", {
                            active: tab === "bids",
                          })}
                          type="button"
                          onClick={() => settab("bids")}
                        >
                          Bids
                        </button>
                      </li>

                      <li className="nav-item" role="presentation">
                        <button
                          className={classnames("nav-link", {
                            active: tab === "activity",
                          })}
                          type="button"
                          onClick={() => settab("activity")}
                        >
                          Activity
                        </button>
                      </li>
                    </ul>

                    <div className="tab-content mt-4 pt-2" id="myTabContent">
                      <div
                        className={classnames("tab-pane fade", {
                          "show active": tab === "details",
                        })}
                      >
                        <p className="text-muted">{props.item.description}</p>

                        <h6 className="mt-3">Blockchain</h6>

                        <div className="creators creator-primary d-flex align-items-center">
                          <h6 className="mb-0 text-dark name">
                            {
                              BLOCKCHAIN_ENUM.filter(
                                (item) => props.item.blockchain === item.value
                              )[0]?.label
                            }
                          </h6>
                        </div>

                        <h6 className="mt-3">Owner</h6>

                        <div className="creators creator-primary d-flex align-items-center">
                          <div className="position-relative">
                            <img
                              src={
                                props.item.current_owner?.profileImage?.link ||
                                "/images/client/09.jpg"
                              }
                              className="avatar avatar-md-sm shadow-md rounded-pill"
                              alt=""
                            />
                            <span className="verified text-primary">
                              <i className="mdi mdi-check-decagram"></i>
                            </span>
                          </div>

                          <div className="ms-3">
                            <h6 className="mb-0">
                              <Link
                                className="text-dark name"
                                to={`/user/${props.item.current_owner?.walletAddress}`}
                              >
                                {props.item.current_owner?.name}
                              </Link>
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div
                        className={classnames("tab-pane fade", {
                          "show active": tab === "bids",
                        })}
                      >
                        {props.itemDetails?.bidsHistory?.map((item) => (
                          <Offer
                            key={`tdt_${item._id}`}
                            noTitle={true}
                            item={item}
                            onReload={() =>
                              onGetItemByToken(params.token_uri_id)
                            }
                            onBidPayment={onBidPayment}
                          />
                        ))}
                      </div>

                      <div
                        className={classnames("tab-pane fade", {
                          "show active": tab === "activity",
                        })}
                      >
                        <div className="row g-4">
                          {props.itemDetails?.tradeHistory?.map((item) => (
                            <div
                              key={`tdt_${item._id}`}
                              className="col-12 mt-0"
                            >
                              <ActivityItem noTitle={true} item={item} />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ Login, items, collections }) => ({
  user: Login.user,
  item: items.item,
  settings: Login.settings,
  itemDetails: items.itemDetails,
  loading: collections.loading || items.loading,
  collection: collections.collection,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCollection: (id, callback) => dispatch(getCollection(id, callback)),
  onGetItemByToken: (token) => dispatch(getItemByToken(token)),
  onPostItemBuy: (data, callback) => dispatch(postItemBuy(data, callback)),
  onPostBid: (data, callback) => dispatch(postBid(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Itemdescription);

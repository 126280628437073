import { call, put, takeEvery } from "redux-saga/effects"

// Collection Redux States
import {
  GET_COLLECTION_NAME_VERIFY,
  GET_COLLECTION_BYTECODE,
  GET_COLLECTIONS,
  POST_COLLECTION,
  GET_COLLECTION,
  GET_COLLECTION_BY_ADDRESS,
  PUT_COLLECTION,
  DELETE_COLLECTION,
} from "./actionTypes"
import {
  getCollectionNameVerifyFail,
  getCollectionNameVerifySuccess,
  getCollectionBytecodeFail,
  getCollectionBytecodeSuccess,
  getCollectionsFail,
  getCollectionsSuccess,
  postCollectionFail,
  postCollectionSuccess,
  getCollectionFail,
  getCollectionSuccess,
  putCollectionFail,
  putCollectionSuccess,
  deleteCollectionFail,
  deleteCollectionSuccess,
} from "./actions"

import {
  getCollectionNameVerify,
  getCollectionBytecode,
  getCollections,
  postCollection,
  getCollection,
  getCollectionByAddress,
  putCollection,
} from "helpers/backend_helper"

function* onGetCollectionNameVerify({ payload: { name, callback } }) {
  try {
    const response = yield call(getCollectionNameVerify, name)

    if (response.status === "failure") {
      return yield put(getCollectionNameVerifyFail(response.message))
    }

    callback && callback(response)

    yield put(getCollectionNameVerifySuccess(response))
  } catch (error) {
    console.log("error", error)
    yield put(getCollectionNameVerifyFail(error))
  }
}

function* onGetCollectionBytecode({ payload: { data, callback } }) {
  try {
    const response = yield call(getCollectionBytecode, data)

    if (response.status === "failure") {
      return yield put(getCollectionBytecodeFail(response.message))
    }

    callback && callback(response)

    yield put(getCollectionBytecodeSuccess(response))
  } catch (error) {
    console.log("error", error)
    yield put(getCollectionBytecodeFail(error))
  }
}

function* fetchCollections({ payload }) {
  try {
    const response = yield call(getCollections, payload)

    if (response.status === "failure") {
      return yield put(getCollectionsFail(response.message))
    }

    yield put(getCollectionsSuccess(response))
  } catch (error) {
    yield put(getCollectionsFail(error))
  }
}

function* onPostCollection({ payload: { collection, callback } }) {
  try {
    const response = yield call(postCollection, collection)

    if (response.status === "failure") {
      window.scrollTo(0, 0)

      return yield put(postCollectionFail(response.message))
    }

    callback && callback(response)

    yield put(postCollectionSuccess())
  } catch (error) {
    console.log("error", error)
    yield put(postCollectionFail("Internal Error!"))
  }
}

function* fetchCollection({ payload: { id, callback } }) {
  try {
    const response = yield call(getCollection, id)

    if (response.status === "failure") {
      return yield put(getCollectionFail(response.message))
    }

    callback && callback(response)

    yield put(getCollectionSuccess(response))
  } catch (error) {
    console.log("error", error)
    yield put(getCollectionFail(error))
  }
}

function* fetchCollectionByAddress({ payload: { address, callback } }) {
  try {
    const response = yield call(getCollectionByAddress, address)

    if (response.status === "failure") {
      return yield put(getCollectionFail(response.message))
    }

    callback && callback(response)

    yield put(getCollectionSuccess(response))
  } catch (error) {
    yield put(getCollectionFail(error))
  }
}

function* onPutCollection({ payload: { data, callback } }) {
  try {
    const response = yield call(putCollection, data)

    if (response.status === "failure") {
      return yield put(putCollectionFail(response.message))
    }

    callback && callback(response)

    yield put(putCollectionSuccess())
  } catch (error) {
    yield put(putCollectionFail("Internal Error!"))
  }
}

/* function* onDeleteCollection({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteCollection, data)

    if (response.status === "failure") {
      return yield put(deleteCollectionFail(response.message))
    }

    yield put(deleteCollectionSuccess())
    callback && callback()
  } catch (error) {
    yield put(deleteCollectionFail("Internal Error!"))
  }
} */

function* CollectionsSaga() {
  yield takeEvery(GET_COLLECTION_NAME_VERIFY, onGetCollectionNameVerify)
  yield takeEvery(GET_COLLECTION_BYTECODE, onGetCollectionBytecode)
  yield takeEvery(GET_COLLECTIONS, fetchCollections)
  yield takeEvery(POST_COLLECTION, onPostCollection)
  yield takeEvery(GET_COLLECTION, fetchCollection)
  yield takeEvery(GET_COLLECTION_BY_ADDRESS, fetchCollectionByAddress)
  yield takeEvery(PUT_COLLECTION, onPutCollection)
  // yield takeEvery(DELETE_COLLECTION, onDeleteCollection)
}

export default CollectionsSaga

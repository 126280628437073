import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import toastr from "toastr";

// Actions
import { getUserByWallet } from "store/actions";

const CreatorProfile = (props) => {
  const { walletOrUsername } = useParams();

  const [isCurrentUser, setisCurrentUser] = useState(false);

  useEffect(() => {
    if (walletOrUsername) {
      props.onGetUserByWallet(walletOrUsername);
    }
  }, [walletOrUsername]);

  useEffect(() => {
    if (props.user?._id === props.userProfile?._id) {
      setisCurrentUser(true);
    } else {
      setisCurrentUser(false);
    }
  }, [props.user, props.userProfile]);

  const copyToClipboard = (text) => {
    var textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand("copy"); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
      // return prompt("Copy to clipboard: Ctrl+C, Enter", text);
    } finally {
      document.body.removeChild(textarea);
    }

    toastr.success("Copied to clipboard!");
  };

  return (
    <React.Fragment>
      <div className="profile-banner">
        <div className="position-relative d-inline-block w-100">
          <img
            src={
              props.userProfile?.bannerImage?.link || "/images/blog/single.jpg"
            }
            className="rounded-md w-100 shadow-sm img-fluid"
            id="profile-banner"
            alt=""
          />
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col">
          <div className="text-center mt-n80">
            <div className="profile-pic">
              <div className="position-relative d-inline-block">
                <img
                  src={
                    props.userProfile?.profileImage?.link ||
                    "/images/client/01.jpg"
                  }
                  className="avatar avatar-medium img-thumbnail rounded-pill shadow-sm"
                  id="profile-image"
                  alt=""
                />
              </div>
            </div>

            <div className="content mt-3">
              <h5 className="mb-3">{props.userProfile?.name}</h5>
              <div className="text-muted mx-2 my-3">
                {props.userProfile?.bio}
              </div>
              <small className="text-muted px-2 py-1 rounded-lg shadow">
                {props.userProfile?.walletAddress}
                <a
                  href=""
                  className="text-primary h5 ms-1"
                  onClick={(e) => {
                    e.preventDefault();
                    copyToClipboard();
                  }}
                >
                  <i className="uil uil-copy"></i>
                </a>
              </small>

              <h6 className="mt-3 mb-0">{props.userProfile?.description}</h6>

              {isCurrentUser && (
                <div className="mt-4">
                  <Link
                    to="/edit-profile"
                    className="btn btn-pills btn-outline-primary mx-1"
                  >
                    Edit Profile
                  </Link>
                  <Link
                    to="/create"
                    className="btn btn-pills btn-icon btn-outline-primary mx-1"
                  >
                    <i className="uil uil-folder-upload"></i>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ Login, Profile }) => ({
  user: Login.user,
  userProfile: Profile.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserByWallet: (wallet) => dispatch(getUserByWallet(wallet)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatorProfile);

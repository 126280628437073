import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";

import { loginUser } from "store/actions";

// Hooks
import useWallet from "hooks/wallet";

// Components
import UserDetails from "./UserDetails";

const Header = (props) => {
  const { account, isActive } = useWallet();
  const navigate = useNavigate();
  const location = useLocation();

  const { search: querySearch } = queryString.parse(location.search);

  const [openDropdown, setOpenDropdown] = useState({
    avatar: false,
    search: false,
    navbar: false,
    modal: false,
  });
  const [search, setsearch] = useState("");

  useEffect(() => {
    setsearch(querySearch);
  }, [querySearch]);

  useEffect(() => {
    if (isActive && !!account && props.user?.walletAddress !== account) {
      props.onLoginUser({ walletAddress: account }, navigate);
    }
  }, [isActive, account, props.user?.walletAddress]);

  const avatarHandler = () => {
    setOpenDropdown((oldData) => {
      return {
        search: false,
        navbar: oldData.navbar,
        modal: false,
        avatar: !oldData.avatar,
      };
    });
  };

  const navbarHandler = () => {
    setOpenDropdown((oldData) => {
      return {
        avatar: false,
        search: false,
        modal: false,
        navbar: !oldData.navbar,
      };
    });
  };

  const searchHandler = () => {
    setOpenDropdown((oldData) => {
      return {
        avatar: false,
        navbar: oldData.navbar,
        modal: false,
        search: !oldData.search,
      };
    });
  };

  const modalHandler = () => {
    setOpenDropdown((oldData) => {
      return {
        avatar: false,
        navbar: oldData.navbar,
        search: false,
        modal: !oldData.modal,
      };
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  const isSticky = (e) => {
    // const header = document.querySelector(".js-header")
    // const scrollTop = window.scrollY
    // console.log("scrollTop ==> ", scrollTop)
    // scrollTop >= 300
    //   ? header.classList.add("is-fixed")
    //   : header.classList.remove("is-fixed")
    // scrollTop >= 400
    //   ? header.classList.add("is-small")
    //   : header.classList.remove("is-small")
  };

  const onSubmit = (evt) => {
    evt.preventDefault();

    navigate(`/explore?search=${search || ""}`);
  };

  return (
    <header id="topnav" className="defaultscroll sticky shadow-lg">
      <div className="container">
        <Link to="/" className="logo">
          <span className="logo-light-mode">
            <img
              src="/images/artspray-logo.png"
              height="26"
              className="l-dark"
              alt=""
            />
            <img
              src="/images/artspray-logo.png"
              height="26"
              className="l-light"
              alt=""
            />
          </span>
          <img
            src="/images/artspray-logo.png"
            height="26"
            className="logo-dark-mode"
            alt=""
          />
        </Link>

        <div className="menu-extras">
          <div className="menu-item">
            <a
              className={"navbar-toggle" + (openDropdown.navbar ? " open" : "")}
              id="isToggle"
              onClick={(e) => {
                e.preventDefault();
                navbarHandler();
              }}
            >
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
          </div>
        </div>

        <ul className="buy-button list-inline mb-0">
          <li className="list-inline-item mb-0 me-1 px-2">
            <form className="search-style" onSubmit={onSubmit}>
              <input
                type="text"
                value={search}
                onChange={(evt) => setsearch(evt.target.value)}
              />
              <img
                src="/images/search.png"
                className="img-fluid"
                onClick={onSubmit}
              />
            </form>

            {/* <button type="button" onClick={searchHandler} className={"btn dropdown-toggle p-0" + (openDropdown.search ? " show" : "")} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded={openDropdown.search}>
                            <i className="uil uil-search text-black title-dark btn-icon-light fs-5 align-middle"></i>
                            <i className="uil uil-search text-dark btn-icon-dark fs-5 align-middle"></i>
                        </button> */}
            {/* <div
                className={
                  "bg-white res-search shadow rounded border-0 mt-0 p-0 show"
                }
                style={{
                }}
              >
                {/* <div className="search-bar">
                  <div id="itemSearch" className="menu-search mb-0">
                    <form
                      role="search"
                      method="get"
                      id="searchItemform"
                      className="searchform"
                    >
                      <input
                        type="text"
                        className="form-control border rounded shadow"
                        name="s"
                        id="s"
                        placeholder="Search..."
                      />
                      <input
                        type="submit"
                        id="searchItemsubmit"
                        value="Search"
                      />
                    </form>
                  </div>
                </div> */}
            {/* </div> */}
          </li>

          {!props.authToken && (
            <li className="list-inline-item mb-0 me-1 px-2 HH">
              <Link
                to="/wallet"
                className="newButtonConnect btn btn-link primary"
                id="connectWallet  "
                onClick={modalHandler}
              >
                WALLET
              </Link>
            </li>
          )}

          {!!props.authToken && (
            <li className="list-inline-item mb-0 px-2">
              <UserDetails
                avatarHandler={avatarHandler}
                openDropdown={openDropdown}
              />
            </li>
          )}
        </ul>

        <div
          id="navigation"
          style={
            openDropdown.navbar ? { display: "block" } : { display: "none" }
          }
        >
          <ul className="navigation-menu nav-light justify-content-center">
            <li className="">
              <Link to="/collections" className="sub-menu-item">
                COLLECTIONS
              </Link>
            </li>
            {props.authToken ? (
              <>
                <li className="">
                  <Link to="/create" className="sub-menu-item">
                    CREATE
                  </Link>
                </li>
              </>
            ) : null}

            <li className="has-submenu parent-parent-menu-item">
              <Link to="/explore">EXPLORE</Link>
            </li>

            {/* <li><Link to="/activity" className="sub-menu-item">Activity</Link></li>
                    <li><Link to="/create" className="sub-menu-item">Create</Link></li> */}
            <li className="has-submenu parent-parent-menu-item">
              <Link to="/about">ABOUT</Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = ({ Login }) => ({
  user: Login.user,
  authToken: Login.authToken,
});

const mapDispatchToProps = (dispatch) => ({
  onLoginUser: (data, callback) => dispatch(loginUser(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

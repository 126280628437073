import React from "react"
import MidSection from "../components/about/MidSection"

import Footer from "../components/footer/Footer"
import Header from "../components/header/Header"

const AboutUs = () => {
  return (
    <React.Fragment>
      <Header />

      <MidSection type="ABOUT_US" />

      <Footer />
    </React.Fragment>
  )
}

export default AboutUs

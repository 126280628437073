import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

//
import NftFilter from "./NftFilter";
import NftExplore from "./NftExplore";

const ExploreTwo = (props) => {
  const location = useLocation();

  const { search } = queryString.parse(location.search);
  const [filter, setfilter] = useState({
    limit: 12,
    orderBy: "_id",
    order: -1,
    page: 1,
    category: [],
    blockchain: [],
    fields: [],
    getNew: false,
  });

  useEffect(() => {
    setfilter((prevState) => ({ ...prevState, search }));
  }, [search]);

  return (
    // <section className="explore-area">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col">
          <div className="section-title text-center mb-4 pb-2">
            <h4 className="title mb-4 PH-font">Explore NFT's</h4>
            <p className="text-muted para-desc mb-0 mx-auto SH-font">
              We are a huge marketplace dedicated to connecting great artists of
              all artspray with their fans and unique token collectors!
            </p>
          </div>
        </div>
      </div>

      <NftFilter filter={filter} setfilter={setfilter} />
      <NftExplore filter={filter} setfilter={setfilter} />
    </div>
    // </section>
  );
};

export default ExploreTwo;

import React from "react"

import Footer from "components/footer/Footer"
import Header from "components/header/Header"
import CreateItem from "components/CreateItem/CreateItem"

const Create = (props) => {
  return (
    <div>
      <Header />
      <CreateItem />
      <Footer />
    </div>
  )
}

export default Create

import React from "react"
import ContactSection from "../components/contact/ContactSection"
import Footer from "../components/footer/Footer"
import Header from "../components/header/Header"

const Contact = () => {
  return (
    <React.Fragment>
      <Header />

      <ContactSection />
      <Footer />
    </React.Fragment>
  )
}

export default Contact

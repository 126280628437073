import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import toastr from "toastr";

// Actions
import { getCollectionByAddress, getUserByWallet } from "store/actions";

const AuthorProfile = (props) => {
  const [state, setstate] = useState({
    isCurrentUser: false,
  });

  useEffect(() => {
    if (props.address) {
      props.onGetCollectionByAddress(props.address);
    }
  }, [props.address]);

  useEffect(() => {
    if (props.user?._id === props.collection?.author?._id) {
      setstate((prevState) => ({ ...prevState, isCurrentUser: true }));
    } else {
      setstate((prevState) => ({ ...prevState, isCurrentUser: false }));
    }
  }, [props.user, props.collection]);

  useEffect(() => {
    if (props.collection && props.collection.author?._id) {
      props.onGetUserByWallet(props.collection.author.username);
    }
  }, [props.collection]);

  const copyToClipboard = (text) => {
    var textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand("copy"); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
      // return prompt("Copy to clipboard: Ctrl+C, Enter", text);
    } finally {
      document.body.removeChild(textarea);
    }

    toastr.success("Copied to clipboard!");
  };

  return (
    <React.Fragment>
      <div className="profile-banner">
        <div className="position-relative d-inline-block w-100">
          <img
            src={
              props.collection?.bannerImage?.link || "/images/blog/single.jpg"
            }
            className="rounded-md w-100 shadow-sm img-fluid"
            id="profile-banner"
            alt=""
          />
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col">
          <div className="text-center mt-n80">
            <div className="profile-pic">
              <div className="position-relative d-inline-block">
                {props.collection?.image?.link &&
                props.collection.image.mimeType?.includes("video") ? (
                  <video
                    src={props.collection.image.link || "/images/client/01.jpg"}
                    className="avatar avatar-medium img-thumbnail rounded-pill shadow-sm"
                    id="profile-image"
                    alt=""
                    controls
                  />
                ) : (
                  <img
                    src={
                      props.collection?.image?.link || "/images/client/01.jpg"
                    }
                    className="avatar avatar-medium img-thumbnail rounded-pill shadow-sm"
                    id="profile-image"
                    alt=""
                  />
                )}
              </div>
            </div>

            <div className="content mt-3">
              <h5 className="collection-text">{props.collection?.name}</h5>
              <div className="text-muted mx-2 my-3">
                {props.collection?.description}
              </div>
              <small className="text-muted px-2 py-1 rounded-lg shadow">
                {props.collection?.contractAddress}
                <a
                  href=""
                  className="text-primary h5 ms-1"
                  onClick={(e) => {
                    e.preventDefault();
                    copyToClipboard(props.collection?.contractAddress);
                  }}
                >
                  <i className="uil uil-copy"></i>
                </a>
              </small>

              <h6 className="created-by mt-3">
                Created by{" "}
                <span>
                  <Link to={`/user/${props.collection.author?.username}`}>
                    {props.collection.author?.name}
                  </Link>
                </span>
              </h6>
            </div>

            <div className="Pieacesofcollection">
              <div className="box1">
                <h6>{props.collectionItems}</h6>
                <p>items</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ Login, collections }) => ({
  user: Login.user,
  collection: collections.collection,
  collectionItems: collections.collectionItems,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCollectionByAddress: (address) =>
    dispatch(getCollectionByAddress(address)),
  onGetUserByWallet: (wallet) => dispatch(getUserByWallet(wallet)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthorProfile);

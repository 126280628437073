import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";

// Components
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Questions from "../components/knowledgebase/Questions";

// Store
import { getFaqs } from "store/actions";

const FAQ = (props) => {
  useEffect(() => {
    props.onGetFaqs({});
  }, []);

  return (
    <>
      <Header />

      <div className="container spinner-content">
        {props.loading && (
          <div className="spinner">
            <Spinner color="primary" />
          </div>
        )}

        <div className="row justify-content-center">
          <div className="col">
            <div className="section-title text-center mb-4 pb-2">
              <h4 className="title mb-4 PH-font">FAQ</h4>
              <p className="text-muted para-desc mb-0 mx-auto SH-font">
                Frequently Asked Questions
              </p>
            </div>
          </div>
        </div>

        <Questions faqs={props.faqs} />
      </div>

      <Footer />
    </>
  );
};

const mapStateToProps = ({ faq }) => ({
  loading: faq.loading,
  faqs: faq.faqs,
});

const mapDispatchToProps = (dispatch) => ({
  onGetFaqs: (data, callback) => dispatch(getFaqs(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);

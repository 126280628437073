// LOGIN
// export const POST_LOGIN = "/user/login"
export const POST_LOGIN = "/user/login-with-wallet";
export const GET_SETTINGS = "/setting/view/";

// SIGNUP
export const POST_REGISTER = "/user/register";

// File
export const POST_FILE = "/file/add";
export const POST_IPFS_FILE = "/file/ipfs";

// User
export const GET_CURRENT_USER = "/user/profile";
export const PUT_PROFILE = "/user/updateProfile";
export const PUT_PROFILE_IMAGE = "/user/updateProfileImage";
export const GET_USER_BY_WALLET = "/user/userby/";

// Home
export const GET_HOMEPAGE = "/home/";
export const GET_MOSTLY_VIEW = "/home/mostly-viewed-nft";
export const GET_LATEST = "/home/latest-nft";
export const GET_TOP_COLLECTIONS = "/home/top-collections";
export const GET_EXPLORE = "/home/expore-nft";

// Collections
export const GET_COLLECTION_NAME_VERIFY = "/collection/byname/";
export const GET_COLLECTION_BYTECODE = "/collection/compile-contract";
export const GET_COLLECTIONS = "/collection/list";
export const POST_COLLECTION = "/collection/add";
export const GET_COLLECTION = "/collection/view/";
export const GET_COLLECTION_BY_ADDRESS = "/collection/viewByAddress/";
export const PUT_COLLECTION = "/collection/update";

// Categories
export const GET_CATEGORIES = "/category/list";

// Items
export const GET_ITEMS = "/item/list";
export const POST_ITEM = "/item/user/add";
export const PUT_ITEM = "/item/user/update";
export const GET_ITEM_BY_TOKEN = "/item/tokenhash/";
export const POST_ITEM_SALE = "/item/user/sale";
export const GET_ACTIVITIES = "/item/activity";
export const POST_ITEM_BUY = "/item/user/buy";
export const GET_ITEM = "/item/user/view/";
export const POST_TOGGLE_ITEM_SALE = "/item/user/sale/toggle";
/* Bids */
export const POST_BID = "/item/user/addoffer";
export const DELETE_BID = "/item/user/removeoffer";
export const POST_BID_ACTION = "/item/user/actionoffer";
export const GET_BIDS = "/item/user/offers";

// Faq
export const GET_FAQS = "/faq/list";

// Content Pages
export const GET_CONTENT_PAGE = "/page/view";

// Contact Us
export const POST_CONTACT_US = "/admin/contact-us";

export const GET_NOTIFICATIONS = "/item/user/notifications";

// prices
export const GET_PRICES =
  "https://api.coingecko.com/api/v3/simple/price?ids=ethereum%2Cmatic-network%2Cbinancecoin%2Cavalanche-2&vs_currencies=usd";

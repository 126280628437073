import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Spinner } from "reactstrap"

// Actions
import { getActivities } from "store/actions"

//
import Activity from "./Activity"

const Activities = (props) => {
  const [activityData, setactivityData] = useState([])

  const [filter, setfilter] = useState({
    limit: 10,
    orderBy: "",
    order: "",
    page: 1,
    author: props.userId,
  })

  useEffect(() => {
    if (props.userId) {
      setfilter((prevState) => ({
        ...prevState,
        author: props.userId,
        page: 1,
      }))
    }
  }, [props.userId])

  useEffect(() => {
    if (!filter.author) return
    props.onGetActivities(filter)
  }, [filter])

  useEffect(() => {
    if (props.activities) {
      if (filter.isMore) {
        setactivityData((prevState) => [...prevState, ...props.activities])
      } else {
        setactivityData(props.activities)
      }
    }
  }, [props.activities])

  const showMoreItems = () => {
    setfilter((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
      isMore: true,
    }))
  }

  return (
    <div className="row items spinner-content">
      {props.loading && (
        <div className="spinner">
          <Spinner color="primary" />
        </div>
      )}

      {activityData.map((item, idx) => {
        return (
          <div key={`eds_${idx}`} className="col-8 item">
            <Activity item={item} />
          </div>
        )
      })}

      {activityData.length < props.totalactivities && (
        <div className="row justify-content-center mt-4">
          <div className="col">
            <div className="text-center">
              <a
                href="#"
                onClick={showMoreItems}
                className="btn btn-primary rounded-md"
              >
                <i className="uil uil-process mdi-spin me-1"></i> Load More
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ items }) => ({
  activities: items.activities,
  loading: items.loading,
  totalactivities: items.totalactivities,
})

const mapDispatchToProps = (dispatch) => ({
  onGetActivities: (data, callback) => dispatch(getActivities(data, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Activities)

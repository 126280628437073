import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Routes, Route } from "react-router-dom"

// Actions
import { getCurrentUser, getSettings } from "store/actions"

// importing all pages
import { authRoutes, publicRoutes, userRoutes } from "pages"

const MyRouts = (props) => {
  useEffect(() => {
    props.getSettings()
  }, [])

  useEffect(() => {
    if (!!props.authToken) {
      props?.getCurrentUser && props.getCurrentUser()
    }
  }, [props.authToken])

  return (
    <Routes>
      {/* Authentication Routes */}
      {!props.authToken &&
        authRoutes.map((route, index) => (
          <Route
            onUpdate={() => window.scrollTo(0, 0)}
            exact={true}
            path={route.path}
            element={route.component}
            key={index}
          />
        ))}

      {/* User Routes */}
      {!!props.authToken &&
        userRoutes.map((route, index) => (
          <Route
            onUpdate={() => window.scrollTo(0, 0)}
            exact={true}
            path={route.path}
            element={route.component}
            key={index}
          />
        ))}

      {publicRoutes.map((route, index) => (
        <Route
          onUpdate={() => window.scrollTo(0, 0)}
          exact={true}
          path={route.path}
          element={route.component}
          key={index}
        />
      ))}
    </Routes>
  )
}

const mapStateToProps = ({ Login }) => ({
  authToken: Login.authToken,
})

const mapDispatchToProps = {
  getCurrentUser,
  getSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyRouts)

import React, { useEffect, useState } from "react";
import {} from "react-router-dom";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";

// Actions
import { getExplore } from "store/actions";
// import Item from "components/UserProfile/Items/Item"
import Item from "components/UserProfile/Items/Item";

const NftExplore = (props) => {
  const [explore, setexplore] = useState([]);

  useEffect(() => {
    props.onGetExplore(props.filter);
  }, [props.filter]);

  useEffect(() => {
    if (props.explore) {
      if (props.filter.isMore) {
        setexplore((prevState) => [...prevState, ...props.explore]);
      } else {
        setexplore(props.explore);
      }
    }
  }, [props.explore]);

  const showMoreItems = (evt) => {
    evt.preventDefault();

    props.setfilter((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
      isMore: true,
    }));
  };

  return (
    <div className="mt-4 mt-sm-0 pt-2 pt-sm-0">
      <div className="row items spinner-content">
        {props.loading && (
          <div className="spinner">
            <Spinner color="primary" />
          </div>
        )}

        {explore.map((item, idx) => (
          <div key={`exo_${idx}`} className="col-12 col-sm-6 col-lg-4 item">
            <Item item={item} />
          </div>
        ))}
      </div>

      {explore.length < props.totalExplore && (
        <div className="row justify-content-center mt-4">
          <div className="col">
            <div className="text-center">
              <a
                href="#"
                onClick={showMoreItems}
                className="btn btn-primary rounded-md"
              >
                <i className="uil uil-process mdi-spin me-1"></i> Load More
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ home }) => ({
  loading: home.loading,
  totalExplore: home.totalExplore,
  explore: home.explore,
});

const mapDispatchToProps = (dispatch) => ({
  onGetExplore: (data, callback) => dispatch(getExplore(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NftExplore);

import {
  GET_CATEGORIES,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  error: "",
  loading: false,
  categories: [],
  totalCategories: 0,
  category: {},
}

const Categories = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Categories */
    case GET_CATEGORIES:
      return {
        ...state,
        loading: true,
      }

    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload.data || [],
        totalCategories: action.payload.totalCount || 0,
        loading: false,
        error: "",
      }

    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Get Categories END */

    default:
      return state
  }
}

export default Categories

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Alert, Spinner } from "reactstrap";

// Constant
import { BLOCKCHAIN_ENUM } from "helpers/constants";

// Action
import {
  postIpfsFile as uploadFile,
  getCollections,
  getCategories,
  postItem,
  getItem,
  putItem,
} from "store/actions";

const Create = (props) => {
  const { onUploadFile } = props;
  let navigate = useNavigate();
  const { _id } = useParams();

  const [fields, setfields] = useState({
    name: "",
    description: "",
    price: "",
    royalties: "",
    media: null,
    imagePreview: null,
    category_id: "",
    collection_id: "",
    blockchain: "ethereum",
  });
  const [collectionfilter, setcollectionfilter] = useState({
    limit: 100,
    orderBy: "",
    order: "",
    page: 1,
    author: props.user?._id,
  });
  const [categoriesfilter, setcategoriesfilter] = useState({
    limit: 100,
    orderBy: "",
    order: "",
    page: 1,
  });

  useEffect(() => {
    if (_id && props.user?._id) {
      props.onGetItem(_id);
    }
  }, [props.user?._id, _id]);

  useEffect(() => {
    if (
      _id &&
      props.item &&
      props.user?._id === props.item.current_owner?._id
    ) {
      setfields({
        itemId: props.item._id,
        name: props.item.name,
        description: props.item.description,
        price: props.item.price,
        royalties: props.item.royalties,
        imagePreview: props.item.media?.link,
        category_id: props.item.category_id,
      });
    }
  }, [props.item, props.user]);

  useEffect(() => {
    if (props.user?._id) {
      setcollectionfilter((prevState) => ({
        ...prevState,
        author: props.user?._id,
      }));
    }
  }, [props.user?._id]);

  useEffect(() => {
    props.onGetCollections(collectionfilter);
  }, [collectionfilter]);

  useEffect(() => {
    props.onGetCategories(categoriesfilter);
  }, [categoriesfilter]);

  const handleChange = (name) => (evt) => {
    const { value } = evt.target;

    setfields((prevState) => ({ ...prevState, [name]: value }));
  };

  const uploadFileSuccess = (name) => (response) => {
    const { _id } = response.data;

    handleChange(name)({ target: { value: _id } });
  };

  const handleImageChange = (evt) => {
    const image = evt.target.files;

    console.log(image[0], "IMAGE");

    if (image && image[0]) {
      onUploadFile({ image: image[0] }, uploadFileSuccess("media"));

      setfields((prevState) => ({
        ...prevState,
        imagePreview: URL.createObjectURL(image[0]),
        imageRaw: image[0],
      }));
    }
  };

  const onSubmit = (evt) => {
    evt.preventDefault();

    if (props.loading) return;
    if (props.fileLoading) return;

    if (_id) {
      props.onPutItem(fields, () => {
        navigate(`/token/${props.item?._id}`);
      });
      return;
    }

    props.onPostItem(fields, () => {
      if (props.user?.walletAddress) {
        navigate(`/user/${props.user?.walletAddress}`);
      } else {
        navigate("/");
      }
    });
  };

  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12  ">
            <div className="card rounded-md shadow p-4">
              <div className="row">
                <div className="col-lg-7 mt-4 mt-lg-0">
                  <div className="ms-lg-4">
                    <form
                      className="form item-form card no-hover spinner-content needs-validation"
                      onSubmit={onSubmit}
                      novalidate
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      {(props.loading || props.fileLoading) && (
                        <div className="spinner">
                          <Spinner color="primary" />
                        </div>
                      )}
                      <div className="row">
                        <div className="col-12 mb-4">
                          <label className="form-label fw-bold">
                            Item Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Item Name"
                            onChange={handleChange("name")}
                            value={fields.name || ""}
                            required
                          />
                        </div>

                        <div className="col-12 mb-4">
                          <label className="form-label fw-bold">
                            Description <span className="text-danger">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            name="textarea"
                            placeholder="Description"
                            cols={30}
                            rows={3}
                            onChange={handleChange("description")}
                            value={fields.description}
                            required
                          />
                        </div>

                        <div className="col-md-6 mb-4">
                          <label className="form-label fw-bold">
                            Price <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="price"
                            placeholder="Item Price"
                            required="required"
                            onChange={handleChange("price")}
                            value={fields.price}
                            step={0.000000001}
                            min={0}
                          />
                        </div>

                        <div className="col-md-6 mb-4">
                          <label className="form-label fw-bold">
                            Royalties <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="royalties"
                            placeholder="Royalties"
                            onChange={handleChange("royalties")}
                            value={fields.royalties}
                            step={0.01}
                            min={0}
                            max={100}
                            required
                          />
                        </div>

                        {!_id && (
                          <div className="col-md-6 mb-4">
                            <label className="form-label fw-bold">Type</label>
                            <select
                              className="form-control"
                              placeholder="Blockchain Type"
                              onChange={handleChange("blockchain")}
                              value={fields.blockchain}
                              required
                            >
                              {BLOCKCHAIN_ENUM.map((item, index) => (
                                <option
                                  key={`chain-${index}`}
                                  value={item.value}
                                >
                                  {item.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}

                        {!_id && (
                          <div className="col-md-6 mb-4">
                            <label className="form-label fw-bold">
                              Collection <span className="text-danger">*</span>
                            </label>
                            <select
                              type="text"
                              className="form-control"
                              name="collection"
                              required="required"
                              onChange={handleChange("collection_id")}
                              value={fields.collection_id}
                            >
                              <option value="">Select Collection</option>
                              {props.collections.map((item) => (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}

                        <div className="col-md-6 mb-4">
                          <label className="form-label fw-bold">
                            Category <span className="text-danger">*</span>
                          </label>
                          <select
                            type="text"
                            className="form-control"
                            name="categories"
                            required="required"
                            onChange={handleChange("category_id")}
                            value={fields.category_id}
                          >
                            <option value="">Select Category</option>
                            {props.categories.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-lg-12">
                          <button
                            type="submit"
                            className="btn btn-primary rounded-md"
                          >
                            {_id ? "Update" : "Create"} Item
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {!_id && (
                  <div className="col-lg-5">
                    <div className="d-grid">
                      <p className="fw-semibold mb-4">
                        Upload your ART here, Please click "Upload Image"
                        Button.
                      </p>
                      <div className="preview-box d-block justify-content-center rounded-md shadow overflow-hidden bg-light text-muted p-2 text-center small">
                        {fields.imageRaw
                          ? fields.imageRaw.name
                          : "Supports JPG, PNG and MP4 videos. Max file size : 100MB."}
                      </div>
                      <input
                        type="file"
                        className="custom-file-input"
                        id="inputGroupFile01"
                        onChange={handleImageChange}
                        accept="image/*"
                        required
                        hidden
                      />
                      <label
                        className="btn-upload btn btn-primary rounded-md mt-4 btn-pfr"
                        for="inputGroupFile01"
                      >
                        Upload Image
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = ({ Login, File, collections, categories, items }) => ({
  user: Login.user,
  fileLoading: File.loading,
  collections: collections.collections,
  categories: categories.categories,
  loading: items.loading,
  error: items.error,
  item: items.item,
});

const mapDispatchToProps = (dispatch) => ({
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
  onGetCollections: (data, callback) =>
    dispatch(getCollections(data, callback)),
  onGetCategories: (data, callback) => dispatch(getCategories(data, callback)),
  onPostItem: (data, callback) => dispatch(postItem(data, callback)),
  onGetItem: (id, callback) => dispatch(getItem(id, callback)),
  onPutItem: (data, callback) => dispatch(putItem(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Create);

import React from 'react'
import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import TermsCollection from '../components/terms/TermsCollection'
import TermsSubHeader from '../components/terms/TermsSubHeader'

const Terms = () => {
  return (
    <React.Fragment>
        <Header />
        <TermsSubHeader />
        {/* <Breadcrumb 
       title={title}
       heading={heading}
       name={name}
       /> */}
        <TermsCollection />
        <Footer />
    </React.Fragment>
  )
}

export default Terms
import React from 'react'
import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import PolicySubHeader from '../components/privacypolicy/PolicySubHeader'
import Privacysection from '../components/privacypolicy/Privacysection'

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
        <Header />
        <PolicySubHeader />
        {/* <Breadcrumb 
       title={title}
       heading={heading}
       name={name}
       /> */}
        <Privacysection />
        <Footer />

    </React.Fragment>
  )
}

export default PrivacyPolicy
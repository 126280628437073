import { call, put, takeEvery } from "redux-saga/effects"

import * as TYPES from "./actionTypes"
import * as ACTIONS from "./actions"
import * as APIS from "helpers/backend_helper"

/* List */
function* onGetFaqs({ payload: { data, callback } }) {
  try {
    const response = yield call(APIS.getFaqs, data)

    if (response.status === "failure") {
      return yield put(ACTIONS.getFaqsFail(response.message))
    }

    callback && callback(response)

    yield put(ACTIONS.getFaqsSuccess(response))
  } catch (error) {
    console.log("List Error", error)
    yield put(ACTIONS.getFaqsFail(error))
  }
}
/* List END */

function* Saga() {
  yield takeEvery(TYPES.GET_FAQS, onGetFaqs)
}

export default Saga

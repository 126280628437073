import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  PUT_PROFILE,
  PUT_PROFILE_FAIL,
  PUT_PROFILE_SUCCESS,
  PUT_PROFILE_IMAGE,
  PUT_PROFILE_IMAGE_FAIL,
  PUT_PROFILE_IMAGE_SUCCESS,
  GET_USER_BY_WALLET,
  GET_USER_BY_WALLET_FAIL,
  GET_USER_BY_WALLET_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  loading: false,
  userProfile: null,
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state, loading: true };
      break;
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload, loading: false };
      break;
    case PROFILE_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;

    case CHANGE_PASSWORD:
      state = { ...state, loading: true };
      break;
    case CHANGE_PASSWORD_SUCCESS:
      state = { ...state, success: action.payload, loading: false };
      break;
    case CHANGE_PASSWORD_FAIL:
      state = { ...state, error: action.payload, loading: false };
      break;

    case PUT_PROFILE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case PUT_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case PUT_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PUT_PROFILE_IMAGE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case PUT_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case PUT_PROFILE_IMAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_USER_BY_WALLET:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case GET_USER_BY_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        userProfile: action.payload,
      };

    case GET_USER_BY_WALLET_FAIL:
      return {
        ...state,
        loading: false,
        userProfile: null,
        error: "",
      };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;

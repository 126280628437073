import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_HOMEPAGE,
  GET_MOSTLY_VIEW,
  GET_LATEST,
  GET_TOP_COLLECTIONS,
  GET_EXPLORE,
} from "./actionTypes"
import {
  getHomepageFail,
  getHomepageSuccess,
  getMostlyViewFail,
  getMostlyViewSuccess,
  getLatestFail,
  getLatestSuccess,
  getTopCollectionsFail,
  getTopCollectionsSuccess,
  getExploreFail,
  getExploreSuccess,
} from "./actions"

import {
  getHomepage,
  getMostlyView,
  getLatest,
  getTopCollections,
  getExplore,
} from "helpers/backend_helper"

function* onGetHomePage({ payload: { data, callback } }) {
  try {
    const response = yield call(getHomepage, data)

    if (response.status === "failure") {
      return yield put(getHomepageFail(response.message))
    }

    callback && callback(response)
    yield put(getHomepageSuccess(response.data))
  } catch (error) {
    console.log("error", error)
    yield put(getHomepageFail(""))
  }
}

function* onGetMostlyView({ payload: { data, callback } }) {
  try {
    const response = yield call(getMostlyView, data)

    if (response.status === "failure") {
      return yield put(getMostlyViewFail(response.message))
    }

    callback && callback(response)
    yield put(getMostlyViewSuccess(response))
  } catch (error) {
    console.log("error", error)
    yield put(getMostlyViewFail(""))
  }
}

function* onGetLatest({ payload: { data, callback } }) {
  try {
    const response = yield call(getLatest, data)

    if (response.status === "failure") {
      return yield put(getLatestFail(response.message))
    }

    callback && callback(response)
    yield put(getLatestSuccess(response))
  } catch (error) {
    console.log("error", error)
    yield put(getLatestFail(""))
  }
}

function* onGetTopCollections({ payload: { data, callback } }) {
  try {
    const response = yield call(getTopCollections, data)

    if (response.status === "failure") {
      return yield put(getTopCollectionsFail(response.message))
    }

    callback && callback(response)
    yield put(getTopCollectionsSuccess(response))
  } catch (error) {
    console.log("error", error)
    yield put(getTopCollectionsFail(""))
  }
}

function* onGetExplore({ payload: { data, callback } }) {
  try {
    const response = yield call(getExplore, data)

    if (response.status === "failure") {
      return yield put(getExploreFail(response.message))
    }

    callback && callback(response)
    yield put(getExploreSuccess(response))
  } catch (error) {
    console.log("error", error)
    yield put(getExploreFail(""))
  }
}

function* homeSaga() {
  yield takeEvery(GET_HOMEPAGE, onGetHomePage)
  yield takeEvery(GET_MOSTLY_VIEW, onGetMostlyView)
  yield takeEvery(GET_LATEST, onGetLatest)
  yield takeEvery(GET_TOP_COLLECTIONS, onGetTopCollections)
  yield takeEvery(GET_EXPLORE, onGetExplore)
}

export default homeSaga

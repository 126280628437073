import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import toastr from "toastr";
import { Spinner } from "reactstrap";

// Actions
import { getCollection, postItemSale, postToggleItemSale } from "store/actions";

// Hooks
import useWallet from "hooks/wallet";

// Util
import { BLOCKCHAIN_CURRENCY_CODE } from "helpers/constants";
import Web3Intraction from "util/web3Intraction";

const Item = ({ needDetails = true, ...props }) => {
  const { walletType } = useWallet();

  const [isCurrentUser, setisCurrentUser] = useState(false);
  const [internalLoading, setinternalLoading] = useState(false);

  useEffect(() => {
    if (
      props.user?._id &&
      props.userProfile?._id &&
      props.user._id === props.userProfile._id &&
      props.item.current_owner?._id === props.user._id
    ) {
      setisCurrentUser(true);
    } else {
      setisCurrentUser(false);
    }
  }, [props.item, props.user, props.userProfile]);

  const onPublish = (evt) => {
    evt.preventDefault();

    if (!window.ethereum || props.walletAddress === null) {
      toastr.error(
        "Connect your Metamask wallet to update the message on the blockchain."
      );
      return;
    }

    if (props.settings && props.item?.blockchain) {
      setinternalLoading(true);

      const web3intraction = new Web3Intraction(
        props.item?.blockchain,
        walletType,
        props.settings
      );

      if (!web3intraction.isNetworkServiceWorking) {
        return toastr.error("Service Not Available!");
      }

      const getCollectionData = async (response) => {
        try {
          const receipt = await web3intraction.mintNFT(
            props.user?.walletAddress,
            response,
            props.item
          );
          console.log("Got receipt", receipt);

          const data = {
            type: props.item?.blockchain || "ethereum",
            itemId: props.item._id,
            transactionHash: receipt.transactionHash,
            token_id: receipt.token_id,
            token_hash: receipt.to,
          };

          props.onPostItemSale(data);
          setinternalLoading(false);
        } catch (error) {
          console.log("error", error);
          setinternalLoading(false);
        }
      };

      web3intraction
        .switchChain()
        .then(() =>
          props.onGetCollection(props.item.collection_id, getCollectionData)
        )
        .catch((error) => {
          toastr.error(error?.message);
          setinternalLoading(false);
        });
    }
  };

  const onToggleSale = (evt) => {
    evt.preventDefault();

    props.onPostToggleItemSale({
      itemId: props.item._id,
      type: props.item?.blockchain || "ethereum",
    });
  };

  return (
    <div className="spinner-content creted-col">
      {(internalLoading || props.loading) && (
        <div className="spinner">
          <Spinner color="primary" />
        </div>
      )}

      <div className="col picture-item">
        <div
          className={`card nft-items nft-primary rounded-md shadow overflow-hidden ${
            needDetails ? "pb-3 my-2" : ""
          }`}
        >
          <div
            className={`nft-image rounded-md position-relative overflow-hidden ${
              needDetails ? "mb-3" : ""
            }`}
          >
            <Link to={!props.item.token_hash ? "" : `/token/${props.item._id}`}>
              {props.item?.media?.link &&
              props.item.media.mimeType?.includes("video") ? (
                <video
                  src={props.item.media?.link}
                  className="img-fluid"
                  alt=""
                  controls
                />
              ) : (
                <img
                  src={props.item.media?.link}
                  className="img-fluid"
                  alt=""
                />
              )}
            </Link>
          </div>
          {needDetails && (
            <div className="d-flex justify-content-between px-3">
              <div className="img-group"></div>

              {/* <span className="like-icon shadow-sm">
                <a href="javascript:void(0)" className="text-muted icon">
                  <i className="mdi mdi-18px mdi-heart mb-0"></i>
                </a>
              </span> */}
            </div>
          )}

          {needDetails && (
            <div className="card-body content position-relative p-0 px-3">
              <Link
                to={!props.item.token_hash ? "" : `/token/${props.item._id}`}
                className="title text-dark h6"
              >
                {props.item.name}
              </Link>

              {!isCurrentUser && (
                <div className="seller d-flex mt-3">
                  <span>Owned By</span>
                  <Link to={`/user/${props.item.current_owner?.walletAddress}`}>
                    <h6 className="mx-2 mb-0">
                      {props.item.current_owner?.name}
                    </h6>
                  </Link>
                </div>
              )}

              <div className="d-flex justify-content-between mt-3 mb-1">
                <small className="rate fw-bold text-black">
                  {" "}
                  {props.item.price}{" "}
                  {BLOCKCHAIN_CURRENCY_CODE[props.item.blockchain]}
                </small>
              </div>

              {isCurrentUser && props.item.status === "created" && (
                <Link
                  className="btn btn-pills btn-primary mb-0 mt-3"
                  to="/publish"
                  onClick={onPublish}
                >
                  <i className="uil uil-shopping-bag mr-2" />
                  Publish
                </Link>
              )}

              {isCurrentUser && props.item.status !== "created" && (
                <Link
                  className="btn btn-pills btn-primary mb-0 mt-3 mx-2"
                  to="/sale"
                  onClick={onToggleSale}
                >
                  {props.item.status === "active" && "Remove from listing"}
                  {props.item.status === "inactive" && "Sell"}
                </Link>
              )}

              {isCurrentUser && (
                <Link
                  className="btn btn-pills btn-primary mb-0 mt-3 mx-2"
                  to={`/edit-item/${props.item._id}`}
                >
                  Edit
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ Login, Profile, collections, items }) => ({
  user: Login.user,
  userProfile: Profile.userProfile,
  settings: Login.settings,
  loading: collections.loading || items.loading,
  collection: collections.collection,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCollection: (id, callback) => dispatch(getCollection(id, callback)),
  onPostItemSale: (data, callback) => dispatch(postItemSale(data, callback)),
  onPostToggleItemSale: (data, callback) =>
    dispatch(postToggleItemSale(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Item);
